import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category, CategoryAdapter } from '../model/Category';
import { City, CityAdapter } from '../model/City';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CitiesService extends ApiService {

  constructor(private http: HttpClient,
              private adapter: CityAdapter) {
    super();
  }

  /**
   * Returns cities from postal code.
   * @param postalCode
   */
  public getCitiesFromPostalCode(postalCode: string): Observable<City[]> {
    let params = new HttpParams();
    params = params.set('country', 'FR')
                   .set('postalCode', postalCode);

    return this.http.get<City[]>(this.baseUri + 'cities', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }
}
