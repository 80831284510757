import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Organisation } from '../../lib/model/Organisation';
import { Transaction } from '../../lib/model/Transaction';
import { OrganisationService } from '../../lib/services/organisation.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';
import { SaleChannel } from 'src/app/lib/model/SaleChannel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public organisations: Organisation[] = [];
  public organisationForm: FormGroup;

  constructor(private organisationService: OrganisationService,
              private sharedVariablesService: SharedVariablesService,
              private transactionService: TransactionService,
              private router: Router,
              private ngxLoaderService: NgxUiLoaderService) {
    this.organisationForm = new FormGroup({
      organisation: new FormControl(undefined)
    });
  }

  ngOnInit() {
    const saleChannel = new SaleChannel();
    saleChannel.code = 'B2CWEB';
    this.sharedVariablesService.saleChannel = saleChannel;

    this.organisationService.getOrganisationListBySaleChannelCode(saleChannel.code).toPromise().then((organisations) => {
      this.organisations = organisations;
    });
  }

  selectOrganisation() {
    this.sharedVariablesService.organisation = this.organisationForm.get('organisation').value;

    let transaction: Transaction;

    transaction = new Transaction(
        undefined,
        'B2CWEB',
        undefined,
        undefined,
        this.sharedVariablesService.organisation.id,
        'FRONT_WEB',
        'WEB');

    const self = this;

    this.ngxLoaderService.start();

    this.transactionService.createOrUpdateTransaction(transaction).toPromise().then((transactionResponse) => {
      self.sharedVariablesService.transaction = transactionResponse;
      this.ngxLoaderService.stop();
      self.router.navigate(['/categories']);
    });
  }
}
