<div class="category-tree">
  <div class="panel">
    <mat-expansion-panel *ngFor="let cat of categories" [expanded]="cat?.code === category?.code || cat?.code === category?.parentCategoryCode">
      <mat-expansion-panel-header [routerLink]="['/produits/', cat?.code]">
        {{ cat?.name }}
      </mat-expansion-panel-header>
      <div *ngIf="cat?.children && cat.children?.length > 0">
        <div *ngFor="let subcategory of cat?.children" class="link">
          <div class="subcategory" [routerLink]="['/produits/', subcategory?.code]" [ngClass]="{'selected': subcategory?.code === category?.code}">{{ subcategory?.name }}</div>
        </div>
      </div>
    </mat-expansion-panel>
  </div>
</div>

