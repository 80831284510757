import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { Article } from '../../lib/model/Article';
import { Product } from '../../lib/model/Product';
import { ProductScheme } from '../../lib/model/ProductScheme';
import { ProductService } from '../../lib/services/product.service';

@Component({
  selector: 'app-product-informations',
  templateUrl: './product-informations.component.html',
  styleUrls: ['./product-informations.component.scss']
})
export class ProductInformationsComponent implements OnInit {
  public article: Article;
  public product: Product;
  public static productScheme: ProductScheme;
  public propertiesMap: { [name: string]: string } = {};
  public ingredientsProperties: string[] = ['recipeComposition', 'allergen', 'nutritionalDeclaration'];
  public producerProperties: string[] = ['supplier', 'supplierDescription'];
  public adviceProperties: string[] = ['usefulInformations', 'conservationCondition', 'advice', 'freshnessGuarantee', 'culinaryDestination'];
  public moreInfoProperties: string[] = ['fishingArea', 'fishingType', 'class', 'gauge', 'productTreatment', 'Nutriscore', 'breed', 'nbPiece', 'quality', 'legalNotice'];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              protected productService: ProductService,
              public dialogRef: MatDialogRef<ProductInformationsComponent>) { }

  ngOnInit() {
    if (!ProductInformationsComponent.productScheme) {
      this.productService.getProductScheme().toPromise().then((productScheme) => {
        ProductInformationsComponent.productScheme = productScheme;
        ProductInformationsComponent.productScheme.productPropertySchemes.forEach((propertyScheme) => {
          this.propertiesMap[propertyScheme.code] = propertyScheme.name;
        });
      });
    } else {
      ProductInformationsComponent.productScheme.productPropertySchemes.forEach((propertyScheme) => {
        this.propertiesMap[propertyScheme.code] = propertyScheme.name;
      });
    }

    if (this.data.article) {
      this.article = this.data.article;
    }
    if (this.data.product) {
      this.product = this.data.product;
    }
  }

  /**
   * Check whether product as
   * @param properties
   */
  productHasProperties(properties: string[]) {
    for (let p of properties) {
      if (this.product.properties[p]) {
        return true;
      }
    }

    return false;
  }
}
