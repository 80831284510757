<form [formGroup]="loginForm" (ngSubmit)="submitLoginForm()" [ngClass]="{ 'in-menu': inMenu }">
  <div>
    <mat-form-field class="full-width">
      <mat-label>Email *</mat-label>
      <input matInput type="text" formControlName="email">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Mot de passe *</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>
  </div>
  <div class="login-buttons">
    <button mat-flat-button type="button" class="forgot-password" (click)="openResetPasswordDialog()">Mot de passe oublié ?</button>
    <button mat-flat-button color="primary" type="submit" [disabled]="!loginForm.valid">Se connecter</button>
  </div>
</form>
