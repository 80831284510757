import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Customer } from '../../lib/model/Customer';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  constructor(private snackbar: MatSnackBar,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  customerUpdated() {
    this.snackbar.open('Votre compte a été créé avec succès ! Vous pouvez désormais vous connecter avec vos identifiants.', undefined, {
      panelClass: ['success-snackbar'],
      duration: 5000,
    });
    this.router.navigate(['/'], { queryParams: { showLogin: 1 }});
  }
}
