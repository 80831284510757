import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Customer } from '../../lib/model/Customer';
import { User } from '../../lib/model/User';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { CustomerService } from '../../lib/services/customer.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-no-account',
  templateUrl: './no-account.component.html',
  styleUrls: ['./no-account.component.scss']
})
export class NoAccountComponent implements OnInit {
  @Output() customerUpdated: EventEmitter<Customer|User> = new EventEmitter<Customer|User>();
  public userForm: FormGroup;

  constructor(public sharedVariablesService: SharedVariablesService,
              private authenticationService: AuthenticationService,
              private customerService: CustomerService,
              public dialog: MatDialog,
              private ngxLoaderService: NgxUiLoaderService) {
    this.userForm = new FormGroup({
      email: new FormControl(undefined, [Validators.required, Validators.pattern(/^((?!\.)[\w-_.]*[\w])(@\w+)(\.\w+(\.\w+)?[^.\W])$/)]),
      advertising: new FormControl(false),
    });
  }

  ngOnInit() {
  }

  submitUserForm() {
    if (this.userForm.valid) {
      let customer = new Customer(
        undefined,
        undefined,
        undefined,
        undefined,
        this.userForm.get('email').value
      );
      if (customer.advertising) {
        customer.optins = {advertising: 'true'};
      }

      if (this.sharedVariablesService.transaction) {
        this.sharedVariablesService.transaction.customer = customer;
      }
      this.customerUpdated.emit(customer);
    }
  }
}
