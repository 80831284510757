import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Article } from '../../../lib/model/Article';
import { Product } from '../../../lib/model/Product';
import { SharedVariablesService } from '../../../lib/services/shared-variables.service';
import { TransactionService } from '../../../lib/services/transaction.service';
import {MatDialog} from '@angular/material/dialog';
import {ProductInformationsComponent} from '../../product-informations/product-informations.component';

@Component({
  selector: 'app-locker-content',
  templateUrl: './locker-content.component.html',
  styleUrls: ['./locker-content.component.scss']
})
export class LockerContentComponent implements OnInit {
  @Input() article: Article;
  @Input() product: Product;
  @Output() articleSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private ngxLoaderService: NgxUiLoaderService,
              private transactionService: TransactionService,
              private snackbar: MatSnackBar,
              public sharedVariablesService: SharedVariablesService,
              private dialog: MatDialog) {

  }

  ngOnInit() {
  }

  addToCart(article: Article) {
    this.ngxLoaderService.start();

    this.transactionService.addArticle(this.sharedVariablesService.transaction, article).toPromise().then((result) => {
      this.ngxLoaderService.stop();
      this.articleSelected.emit(true);
      this.sharedVariablesService.transaction = result;

      this.snackbar.open('Panier mis à jour avec succès !', undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    });
  }

  openProductInformations(article: Article) {
    const dialogRef = this.dialog.open(ProductInformationsComponent, {
      data: {article, product: this.product},
      panelClass: 'full-width-dialog',
      width: '50%',
    });

    dialogRef.afterClosed().subscribe(actionDone => {
      // this.getActions();
      // if (actionDone) {
      //   this.close.emit();
      // }
    });
  }
}
