import { Injectable } from '@angular/core';
import {Page} from './Page';
import { ArticleAdapter, Article } from './Article';
import {Adapter} from '../interfaces/adapter.interface';

export class ArticlePage extends Page {
  content: Article[];

  constructor(totalPages?: number, totalElements?: number, size?: number, currentPage?: number, content?: Article[]) {
    super(totalPages, totalElements, size, currentPage);
    this.content = content;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ArticlePageAdapter implements Adapter<ArticlePage> {

  adapt(item: any): ArticlePage {
    const articleAdapter: ArticleAdapter = new ArticleAdapter();

    return new ArticlePage(
      item.totalPages,
      item.totalElements,
      item.size,
      item.currentPage,
      item.content ? item.content.map(e => articleAdapter.adapt(e)) : []
    );
  }
}
