import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Article } from '../model/Article';
import { Item } from '../model/Item';
import { Option } from '../model/Option';
import { Product } from '../model/Product';
import { ProductVariant } from '../model/ProductVariant';
import { Transaction, TransactionAdapter } from '../model/Transaction';
import { ApiService } from './api.service';
import { SharedVariablesService } from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionService extends ApiService {

  constructor(private http: HttpClient,
              private sharedVariablesService: SharedVariablesService,
              private transactionAdapter: TransactionAdapter) {
    super();
  }

  /**
   * Get a single transaction from its id.
   * @param transactionId
   */
  public getTransaction(transactionId: string): Observable<Transaction> {
    let params = new HttpParams();

    return this.http.get<Transaction>(this.baseUri + 'transactions/' + transactionId, {params}).pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Create / update transaction.
   * @param transaction
   */
  public createOrUpdateTransaction(transaction: Transaction): Observable<Transaction> {
    let transactionId = '';
    if (transaction.id) {
      transactionId = transaction.id;
    }

    return this.http.put<Transaction>(this.baseUri + 'transactions/' + transactionId, this.transactionAdapter.prepare(transaction))
      .pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Add a product-item to a transaction.
   * @param transaction
   * @param product
   * @param quantity
   * @param options
   */
  public addProduct(transaction: Transaction, product: ProductVariant|Product|Item, quantity: number, options: Option[] = []): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.post<Transaction>(this.baseUri + 'transactions/' + transaction.id + '/' + product.ean, {
      ean: product.ean,
      options: options,
      quantity,
    }, {params}).pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Delete a product-item from a transaction.
   * @param transaction
   * @param productId
   */
  public deleteProduct(transaction: Transaction, productId: string): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.delete(this.baseUri + 'transactions/' + transaction.id + '/' + productId, {params})
      .pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Retrieve transaction corresponding of refundingKey
   * @param refundingKey
   * @param instanceName
   */
  getTransactionForRefund(refundingKey: string, instanceName: string): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.get<Transaction>(this.baseUri + 'transactions/refundingKey/' + instanceName + '/' + refundingKey, {params})
      .pipe(map(item => this.transactionAdapter.adapt(item)));
  }

  /**
   * Refund transaction (with Stripe)
   * @param refundKey
   * @param instanceName
   */
  refundTransactionStripe(refundKey: string, instanceName: string): Observable<any> {
    const params = new HttpParams();
    return this.http.post<Transaction>(this.baseUri + 'stripe/refundRequest/' + instanceName + '/' + refundKey, {params});
  }

  /**
   * Add a article to a transaction.
   * @param transaction
   * @param article
   */
  public addArticle(transaction: Transaction, article: Article): Observable<Transaction> {
    const params = new HttpParams();
    return this.http.post<Transaction>(this.baseUri + 'transactions/' + transaction.id + '/' + article.ean, {
      ean: article.ean,
      locationId: article.locationId,
      quantity: 1
    }, {params}).pipe(map(item => this.transactionAdapter.adapt(item)));
  }
}
