import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format locationId to location number.
 */
@Pipe({
  name: 'locationNumber',
})
export class LocationNumberPipe implements PipeTransform {
  constructor() {
  }

  transform(locationId: string): string {
    const locationNumberArray = locationId.split(/([0-9]+)/);
    let locationNumber: string;

    locationNumberArray.forEach(value => {
      if (+value) {
        locationNumber = value;
      }
    });

    return locationNumber;
  }
}
