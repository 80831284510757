import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Customer, CustomerAdapter } from '../model/Customer';
import { ApiService } from './api.service';
import { SharedVariablesService } from './shared-variables.service';

@Injectable()
export class CustomerService extends ApiService {
  readonly CUSTOMER_API_URL: string = 'customers';

  constructor(private http: HttpClient,
              private adapter: CustomerAdapter,
              private sharedVariablesService: SharedVariablesService) {
    super();
  }

  /**
   * Create a customer
   * @param customer
   */
  createCustomer(customer: Customer) {
    const params = new HttpParams();
    return this.http.post(this.baseUri + this.CUSTOMER_API_URL, this.adapter.prepare(customer), {params})
      .pipe(map((item: any) => this.adapter.adapt(item)));
  }

  /**
   * Update a customer
   * @param customer
   */
  updateCustomer(customer: Customer) {
    const params = new HttpParams();
    return this.http.post(this.baseUri + this.CUSTOMER_API_URL + '/' + customer.id.replace(/\+/gi, '%2B'), this.adapter.prepare(customer), {params})
      .pipe(map((item: any) => this.adapter.adapt(item))
      );
  }

  /**
   * Get full customer with addresses
   * @param customerId
   */
  getCustomer(customerId: string): Observable<Customer> {
    const params = new HttpParams();
    return this.http.get(this.baseUri + this.CUSTOMER_API_URL + '/' + customerId.replace(/\+/gi, '%2B'), {params})
      .pipe(map((item: any) => this.adapter.adapt(item))
      );
  }

  /**
   * Subscribe an email to the newsletter
   * @param email
   */
  subscribeNewsletter(email: string): Observable<any> {
    const params = new HttpParams();
    return this.http.post(this.baseUri + '/fraichConnection/newsletter/subscribe/' + email.replace(/\+/gi, '%2B'), undefined,{params});
  }

  /**
   * Request a reset password.
   * @param userEmail
   */
  requestResetPassword(userEmail: string) {
    let headers = new HttpHeaders();
    headers = headers.set('InstanceId', this.sharedVariablesService.instance);

    return this.http.get(this.baseUriNoAuthKey + 'customers/' + userEmail.replace(/\+/gi, '%2B') + '/resetPassword', {headers});
  }

  /**
   * Reset password.
   * @param email
   * @param validationCode
   * @param password
   */
  resetPassword(email: string, validationCode: string, password: string): Observable<any> {
    const params = new HttpParams();
    const headers = new HttpHeaders();
    return this.http.post<any>(this.baseUri + '/customers/' + email.replace(/\+/gi, '%2B') + '/password/' + validationCode, {password}, {headers, params});
  }
}
