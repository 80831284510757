import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { Address, AddressType } from '../../lib/model/Address';
import { City } from '../../lib/model/City';
import { Customer } from '../../lib/model/Customer';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { CitiesService } from '../../lib/services/cities.service';
import { CustomerService } from '../../lib/services/customer.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import moment from 'moment';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})
export class CustomerFormComponent implements OnInit, OnDestroy {
  @Output() customerUpdated: EventEmitter<Customer> = new EventEmitter<Customer>();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() accountPage: boolean = false;
  @ViewChild(MatAutocompleteTrigger) cityAutocomplete: MatAutocompleteTrigger;
  private customerLoggedSubscription: Subscription;
  public userForm: FormGroup;
  public customer: Customer;
  public editPassword: boolean = false;
  public cities: City[] = [];

  constructor(public sharedVariablesService: SharedVariablesService,
              private authenticationService: AuthenticationService,
              private customerService: CustomerService,
              private citiesService: CitiesService,
              private ngxLoaderService: NgxUiLoaderService) {

    this.customerLoggedSubscription = this.authenticationService.currentUserSubject.subscribe((customerLogged) => {
      window.setTimeout(() => {
        this.ngOnInit();
      }, 500);
    });

    this.initForm();
  }

  ngOnDestroy(): void {
    this.customerLoggedSubscription.unsubscribe();
  }

  ngOnInit() {
    if (this.authenticationService.getCurrentUser()) {
      this.customerService.getCustomer((this.authenticationService.getCurrentUser() as Customer).id).toPromise().then((customer) => {
        this.customer = customer;
        this.initForm();
      });
    }
    else if (this.sharedVariablesService.transaction && this.sharedVariablesService.transaction.customer && this.sharedVariablesService.transaction.customer.id) {
      this.customerService.getCustomer(this.sharedVariablesService.transaction.customer.id).toPromise().then((customer) => {
        this.editPassword = false;
        this.customer = customer;
        this.initForm();
      });
    }
  }

  cancelEdit() {
    this.userForm.reset();
    this.cancel.emit(true);
  }

  initForm() {
    this.userForm = new FormGroup({
      civility: new FormControl(this.customer ? this.customer.civility : undefined, Validators.required),
      lastName: new FormControl(this.customer ? this.customer.lastName : undefined, Validators.required),
      firstName: new FormControl(this.customer ? this.customer.firstName : undefined, Validators.required),
      phone: new FormControl(this.customer ? this.customer.phone : undefined, [Validators.pattern(/^(\d){10}$/)]),
      email: new FormControl(this.customer ? this.customer.email : undefined, [Validators.required, Validators.pattern(/^((?!\.)[a-z0-9-_.+]*[a-z0-9])(@[a-z0-9-]+)(\.[a-z0-9]+(\.[a-z0-9]+)?)$/)]),
      password: new FormControl(undefined, (!this.customer) ? Validators.required : []),
      postalCode: new FormControl(this.customer && this.customer.address ? this.customer.address.postalCode : undefined, []),
      city: new FormControl(this.customer && this.customer.address ? this.customer.address.city : undefined, []),
      line1: new FormControl(this.customer && this.customer.address ? this.customer.address.line1 : undefined, []),
      advertising: new FormControl((this.customer && this.customer.optins) ? this.customer.optins['advertising'] : false),
      birthDay: new FormControl(this.customer && this.customer.birthDay ? this.customer.birthDay.toLocaleDateString('fr') : undefined),
    });
  }

  submitUserForm() {
    if (this.userForm.valid) {
      if (this.customer && this.customer.id) {
        this.customer.firstName = this.userForm.get('firstName').value[0].toUpperCase() + this.userForm.get('firstName').value.substring(1);
        this.customer.lastName = this.userForm.get('lastName').value[0].toUpperCase() + this.userForm.get('lastName').value.substring(1);
        this.customer.email = this.userForm.get('email').value;
        this.customer.civility = this.userForm.get('civility').value;
        if (this.userForm.get('password').value) {
          this.customer.password = this.userForm.get('password').value;
        }
        this.customer.phone = this.userForm.get('phone').value;
        this.customer.advertising = this.userForm.get('advertising').value;
        if (this.userForm.get('birthDay').value) {
          this.customer.birthDay = new Date(moment(this.userForm.get('birthDay').value + ' 12:00', "DD/MM/YYYY hh:mm").toDate());
        }
        this.customer.optins = {advertising: this.userForm.get('advertising').value ? 'true' : 'false'};

        this.customer.postalCode = this.userForm.get('postalCode').value ? this.userForm.get('postalCode').value : '';
        this.customer.address = new Address(this.customer.address ? this.customer.address.id : undefined,
          undefined,
          undefined,
          AddressType.INVOICE,
          undefined,
          this.userForm.get('phone').value ? this.userForm.get('phone').value : undefined,
          this.userForm.get('line1').value ? this.userForm.get('line1').value : undefined,
          undefined,
          undefined,
          this.userForm.get('postalCode').value ? this.userForm.get('postalCode').value : undefined,
          this.userForm.get('city').value ? this.userForm.get('city').value.toUpperCase() : undefined);

        this.ngxLoaderService.start();
        this.customerService.updateCustomer(this.customer).toPromise().then((customer) => {
          if (this.sharedVariablesService.transaction) {
            this.sharedVariablesService.transaction.customer = customer;
          }
          this.customerUpdated.emit(customer);
          this.ngxLoaderService.stop();
        });
      } else {
        let customer = new Customer(
          this.userForm.get('firstName').value[0].toUpperCase() + this.userForm.get('firstName').value.substring(1),
          this.userForm.get('lastName').value[0].toUpperCase() + this.userForm.get('lastName').value.substring(1),
          this.userForm.get('postalCode').value ? this.userForm.get('postalCode').value : '',
          this.userForm.get('email').value,
          this.userForm.get('email').value,
          this.userForm.get('phone').value,
        );
        customer.civility = this.userForm.get('civility').value;
        customer.advertising = this.userForm.get('advertising').value;
        customer.optins = {advertising: this.userForm.get('advertising').value ? 'true' : 'false'};

        customer.password = this.userForm.get('password').value;

        customer.address = new Address(undefined,
          undefined,
          undefined,
          AddressType.INVOICE,
          undefined,
          this.userForm.get('phone').value ? this.userForm.get('phone').value : undefined,
          this.userForm.get('line1').value ? this.userForm.get('line1').value : undefined,
          undefined,
          undefined,
          this.userForm.get('postalCode').value ? this.userForm.get('postalCode').value : undefined,
          this.userForm.get('city').value ? this.userForm.get('city').value.toUpperCase() : undefined);

        this.ngxLoaderService.start();
        this.customerService.createCustomer(customer).toPromise().then((customer) => {
          if (this.sharedVariablesService.transaction) {
            this.sharedVariablesService.transaction.customer = customer;
          }
          this.customerUpdated.emit(customer);
          this.ngxLoaderService.stop();
        });
      }
    }
  }

  showPassword() {
    this.editPassword = !this.editPassword;
    if (this.editPassword) {
      this.userForm.get('password').setValidators([Validators.required]);
    } else {
      this.userForm.get('password').clearValidators();
    }
  }

  postalCodeChanged() {
    // If postal code 5 char long, we look for cities
    if (this.userForm.get('postalCode').value && this.userForm.get('postalCode').value.length == 5) {
      this.ngxLoaderService.start();
      this.citiesService.getCitiesFromPostalCode(this.userForm.get('postalCode').value).toPromise().then((results) => {
        this.cities = results;
        this.ngxLoaderService.stop();
        this.cityAutocomplete.openPanel();
      });
    } else {
      this.cities = [];
    }
  }
}
