import { Injectable } from '@angular/core';
import {Adapter} from '../interfaces/adapter.interface';

export class ProductImage {
  id: string;
  type: ProductImageType;
  name: string = '';
  url: string = '';
  rank: number = 0;
  externalId: string;

  constructor(id?: string, type?: ProductImageType, name?: string, url?: string, rank?: number, externalId?: string) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.url = url;
    this.rank = rank;
    this.externalId = externalId;
  }
}

export enum ProductImageType {
  IMAGE = 'IMAGE',
  IMAGE_DEFAULT = 'DEFAULT_IMAGE',
  PICTO = 'PICTO',
  PICTO_DEFAULT = 'DEFAULT_PICTO',
}

@Injectable({
  providedIn: 'root',
})
export class ProductImageAdapter implements Adapter<ProductImage> {
  adapt(item: any): ProductImage {
    return new ProductImage(
      item.id ? item.id : undefined,
      item.type,
      item.name,
      item.url,
      item.rank,
      item.externalId
    );
  }
}
