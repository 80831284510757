<div class="product-information-dialog">
  <div class="float-right">
    <mat-icon class="link" (click)="dialogRef.close()">close</mat-icon>
  </div>

  <div class="product-image">
    <img [src]="product.getDefaultImage().url" [alt]="product.name">
  </div>

  <div class="information-lists">
    <p class="sm"><strong>{{ article.product.name }}</strong></p>
    <p *ngIf="article.product.properties['latinName']">{{ article.product.properties['latinName'] }}</p>
    <p>
      <span *ngIf="article.product.properties['origin']">Origine : {{ article.product.properties['origin'] }}<br></span>
      <span>Prix/{{article.product.unitCapacityLabel}} : {{ article.price.unitCapacityPrice | price }}/{{ product.unitCapacityLabel }}</span><br>
      <span>Référence : {{ product.id }}</span><br>
      <span>EAN : {{ product.ean }}</span><br>
      <span>Conditionnement : {{ product.packagingLabel }}</span><br>
      <span>{{ product.description }}</span>
    </p>

    <mat-accordion class="accordion">
      <mat-expansion-panel *ngIf="productHasProperties(ingredientsProperties)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Composition
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- List -->
        <ng-container *ngFor="let p of ingredientsProperties">
          <div class="list-info" *ngIf="product.properties[p]">
            <div class="label" *ngIf="propertiesMap[p]">{{ propertiesMap[p] }}</div>
            <div class="value" [innerHTML]="product.properties[p]"></div>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="productHasProperties(producerProperties)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Producteur
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- List -->
        <ng-container *ngFor="let p of producerProperties">
          <div class="list-info" *ngIf="product.properties[p]">
            <div class="label" *ngIf="propertiesMap[p]">{{ propertiesMap[p] }}</div>
            <div class="value" [innerHTML]="product.properties[p]"></div>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="productHasProperties(adviceProperties)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Conseils
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- List -->
        <ng-container *ngFor="let p of adviceProperties">
          <div class="list-info" *ngIf="product.properties[p]">
            <div class="label" *ngIf="propertiesMap[p]">{{ propertiesMap[p] }}</div>
            <div class="value" [innerHTML]="product.properties[p]"></div>
          </div>
        </ng-container>
      </mat-expansion-panel>

      <mat-expansion-panel *ngIf="productHasProperties(moreInfoProperties)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            Informations complémentaires
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- List -->
        <ng-container *ngFor="let p of moreInfoProperties">
          <div class="list-info" *ngIf="product.properties[p]">
            <div class="label" *ngIf="propertiesMap[p]">{{ propertiesMap[p] }}</div>
            <div class="value" [innerHTML]="product.properties[p]"></div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>
