import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataGuard } from './lib/helpers/data-guards';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { DefineNewPasswordComponent } from './pages/define-new-password/define-new-password.component';
import { FinalizeComponent } from './pages/finalize/finalize.component';
import { HomeComponent } from './pages/home/home.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { ProductsComponent } from './pages/products/products.component';
import { RecapComponent } from './pages/recap/recap.component';
import { SuccessComponent } from './pages/success/success.component';
import { ResetPasswordComponent } from './partials/reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'categories',
    component: CategoriesComponent,
    canActivate: [DataGuard],
  },
  {
    path: 'recherche',
    component: ProductsComponent,
    data: {forceReload: true},
    canActivate: [DataGuard],
  },
  {
    path: 'produits/:categorycode',
    component: ProductsComponent,
    data: {reuseRoute: true},
    canActivate: [DataGuard],
  },
  {
    path: 'finaliser-ma-commande',
    component: FinalizeComponent,
    canActivate: [DataGuard],
  },
  {
    path: 'recapitulatif',
    component: RecapComponent,
    canActivate: [DataGuard],
  },
  {
    path: 'confirmation-de-commande/:id',
    component: SuccessComponent,
  },
  {
    path: 'mon-compte',
    component: MyAccountComponent,
  },
  {
    path: 'creer-un-compte',
    component: CreateAccountComponent,
  },
  {
    path: 'reinitialiser-mon-mot-de-passe/:email/:validationCode',
    component: DefineNewPasswordComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
