<div class="select-location-modal-component">
  <div class="float-right">
    <mat-icon class="link" (click)="dialogRef.close()">close</mat-icon>
  </div>

  <div>
    <h2 class="mt0">FAITES VOTRE CHOIX !</h2>
  </div>

  <!-- LOCKERS -->
  <div class="lockers-list row">
    <div class="col-md-4  pb20" *ngFor="let article of articles">
      <app-locker-content [article]="article" [product]="product" (articleSelected)="articleSelected()"></app-locker-content>
    </div>
  </div>
</div>
