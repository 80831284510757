import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class Price {
  id: string;
  ean?: string;
  productId?: string;
  declinationId?: string;
  organisationId: string;
  normalPrice?: number;
  strikeThroughPrice?: number;
  proPrice?: number;
  proPriceModificationDate?: Date;
  operationType?: string;
  unitCapacityPrice?: number;
  proUnitCapacityPrice?: number;
  ecopart?: number;
  deee?: number;
  strikeThroughPriceStartDate?: Date;
  strikeThroughPriceEndDate?: Date;
  strikeThroughPriceModificationDate?: Date;
  properties?: { [name: string]: string[] };
  priceScaleCode?: string;
  purchasingPrice?: number;
  marginRate?: number;
  transferPrice?: number;
  displayedDiscountRate?: number;

  creationDate: Date;
  modificationDate: Date;

  constructor(id?: string, ean?: string, productId?: string, declinationId?: string, organisationId?: string, normalPrice?: number,
              strikeThroughPrice?: number, proPrice?: number, proPriceModificationDate?: Date, operationType?: string, unitCapacityPrice?: number,
              proUnitCapacityPrice?: number, ecopart?: number, deee?: number, strikeThroughPriceStartDate?: Date,
              strikeThroughPriceEndDate?: Date, strikeThroughPriceModificationDate?: Date, properties?: { [p: string]: string[] },
              priceScaleCode?: string, purchasingPrice?: number, transferPrice?: number, marginRate?: number, displayedDiscountRate?: number,
              creationDate?: Date, modificationDate?: Date) {
    this.id = id;
    this.ean = ean;
    this.productId = productId;
    this.declinationId = declinationId;
    this.organisationId = organisationId;
    this.normalPrice = normalPrice;
    this.strikeThroughPrice = strikeThroughPrice;
    this.proPrice = proPrice;
    this.proPriceModificationDate = proPriceModificationDate;
    this.operationType = operationType;
    this.unitCapacityPrice = unitCapacityPrice;
    this.proUnitCapacityPrice = proUnitCapacityPrice;
    this.ecopart = ecopart;
    this.deee = deee;
    this.strikeThroughPriceStartDate = strikeThroughPriceStartDate;
    this.strikeThroughPriceEndDate = strikeThroughPriceEndDate;
    this.strikeThroughPriceModificationDate = strikeThroughPriceModificationDate;
    this.properties = properties;
    this.priceScaleCode = priceScaleCode;
    this.purchasingPrice = purchasingPrice;
    this.transferPrice = transferPrice;
    this.marginRate = marginRate;
    this.displayedDiscountRate = displayedDiscountRate;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PriceAdapter implements Adapter<Price> {
  adapt(item: any): Price {
    if (item) {
      return new Price(
        item.id,
        item.ean,
        item.productId,
        item.declinationId,
        item.organisationId,
        item.normalPrice,
        item.strikeThroughPrice,
        item.proPrice,
        item.proPriceModificationDate ? new Date(item.proPriceModificationDate.toString()) : undefined,
        item.operationType,
        item.unitCapacityPrice,
        item.proUnitCapacityPrice,
        item.ecopart,
        item.deee,
        item.strikeThroughPriceStartDate ? new Date(item.strikeThroughPriceStartDate.toString()) : undefined,
        item.strikeThroughPriceEndDate ? new Date(item.strikeThroughPriceEndDate.toString()) : undefined,
        item.strikeThroughPriceModificationDate ? new Date(item.strikeThroughPriceModificationDate.toString()) : undefined,
        item.properties,
        item.priceScaleCode,
        item.purchasingPrice,
        item.transferPrice,
        item.marginRate,
        item.displayedDiscountRate,
        item.creationDate ? new Date(item.creationDate.toString()) : undefined,
        item.modificationDate ? new Date(item.modificationDate.toString()) : undefined
      );
    } else {
      return new Price();
    }

  }
}
