import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class PaymentRequestPage {
  hostedPageUrl: string;
  sessionId: string;
  apiPublicKey: string;

  constructor(hostedPageUrl?: string, sessionId?: string, apiPublicKey?: string) {
    this.hostedPageUrl = hostedPageUrl;
    this.sessionId = sessionId;
    this.apiPublicKey = apiPublicKey;
  }
}

@Injectable({
  providedIn: 'root',
})
export class PaymentRequestPageAdapter implements Adapter<PaymentRequestPage> {
  adapt(item: any): PaymentRequestPage {
    return new PaymentRequestPage(
      item.hostedPageUrl,
      item.sessionId,
      item.apiPublicKey);
  }
}
