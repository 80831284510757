import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Customer } from '../../lib/model/Customer';
import { Order } from '../../lib/model/Order';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { CustomerService } from '../../lib/services/customer.service';
import { OrderService } from '../../lib/services/order.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {
  public customer: Customer;
  public editCustomer: boolean = false;
  public orders: Order[] = [];
  public allOrdersLoaded: boolean = false;
  public page: number = 0;

  // TODO Use customer auth key to make requests on order service
  constructor(public sharedVariablesService: SharedVariablesService,
              public authenticationService: AuthenticationService,
              private customerService: CustomerService,
              private orderService: OrderService,
              private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit(): void {
    this.ngxLoaderService.start();

    if (this.authenticationService.getCurrentUser()) {
      this.customerService.getCustomer((this.authenticationService.getCurrentUser() as Customer).id).toPromise().then((customer) => {
        this.customer = customer;
        this.loadOrders();
      });
    }
  }

  loadOrders() {
    this.orderService.findOrdersForCustomerId(this.customer.id, this.page).toPromise().then((orderPage) => {
      this.orders = this.orders.concat(orderPage.content);
      this.ngxLoaderService.stop();

      this.page++;
      if (orderPage.totalPages === this.page) {
        this.allOrdersLoaded = true;
      }
    });
  }

  toggleEditCustomer(customer: Customer) {
    this.editCustomer = !this.editCustomer;
    if (customer) {
      this.customer = customer;
    }
  }

  logout() {
    this.authenticationService.logout();
  }
}
