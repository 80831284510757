<div class="full-width container-fluid how-it-works">
  <div class="text-center">
    <div class="suptitle">Comment ça marche ?</div>
    <h2>À deux pas de chez vous, sans prise de chou !</h2>
    <div class="row">
      <div class="col-md-4 p10">
        <div class="picture">
          <img src="/assets/images/ordinateur.svg" alt="Commander">
        </div>
        <div class="step">1</div>
        <p>Ajoutez vos produits au panier et payez</p>
      </div>
      <div class="col-md-4 p10">
        <div class="picture">
          <img src="/assets/images/pedago-velo.svg" alt="Vélo">
        </div>
        <div class="step">2</div>
        <p>Rendez-vous au village Ouvert 7j/7 24h/24 !</p>
      </div>
      <div class="col-md-4 p10">
        <div class="picture">
          <img src="/assets/images/borne.svg" alt="Borne">
        </div>
        <div class="step">3</div>
        <p>Une fois sur place, entrez votre code sur la borne et prenez vos courses !</p>
      </div>
    </div>
  </div>
</div>
<div class="vegetables"></div>
<div class="full-width container-fluid footer-items text-center">
  <div class="row">
    <div class="col-sm-4 col-md-3 col-md-offset-1 slot">
      <h3>La Fraîch' Connection</h3>
      <p><a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#111;&#110;&#116;&#97;&#99;&#116;&#64;&#102;&#114;&#97;&#105;&#99;&#104;&#99;&#111;&#110;&#110;&#101;&#99;&#116;&#105;&#111;&#110;&#46;&#99;&#111;&#109;">Contactez-nous</a></p>
      <p><a href="https://img.keyneosoft.io/fraichconnection/public/pdf/Mentions_Legales.pdf">Mentions légales</a><br>
          <a href="https://img.keyneosoft.io/fraichconnection/public/pdf/Politique_de_confidentialite.pdf" target="_blank">Politique de confidentialité</a><br>
          <a href="https://img.keyneosoft.io/fraichconnection/public/pdf/CGV.pdf" target="_blank">Conditions Générales de Vente</a></p>
      <p><a href="https://fraichconnection.com/" target="_blank">En savoir plus</a></p>
    </div>
    <div class="col-sm-4 col-md-4 slot">
      <h3>Téléchargez la Fraîch' Appli</h3>
      <p>Avec la Fraîch’ Appli, faites vos courses simplement depuis votre canapé ou gagnez du temps directement dans nos villages.</p>
      <p>
        <a href="https://apps.apple.com/us/app/fraichconnection/id1564323065" target="_blank"><img class="mr10 mb10" alt="ios-store" src="/assets/images/app-store-badge.svg"></a>
        <a href="https://play.google.com/store/apps/details?id=com.keyneosoft.b2c.fraichconnection" target="_blank"><img class="mb10" alt="android-store" src="/assets/images/google-play-badge.svg"></a>
      </p>
    </div>
    <div class="col-sm-4 col-md-3 slot">
      <h3>Recevez notre newsletter !</h3>
      <p>Pour recevoir nos actualités et offres exclusives, inscrivez-vous ! Promis, pas de salades, juste du frais et du croustillant !</p>
      <form class="newsletter-form" [formGroup]="newsletterForm" (ngSubmit)="subscribeNewsletter()">
        <input formControlName="email" type="text" placeholder="Votre email">
        <button mat-stroked-button type="submit" color="primary"><mat-icon svgIcon="carotte"></mat-icon> OK</button>
      </form>
      <p class="error mt10" *ngIf="emailError">Veuillez entrer une adresse email valide.</p>
    </div>
  </div>
</div>
<div class="text-center container social">
  <h3>Et pour nous suivre ? </h3>
  <div>
    <a href="https://www.facebook.com/fraichconnection" target="_blank" class="link"><mat-icon class="facebook" svgIcon="facebook"></mat-icon></a>
    <a href="https://www.instagram.com/fraichconnection/?hl=fr" target="_blank" class="link"><mat-icon class="instagram" svgIcon="instagram"></mat-icon></a>
    <a href="https://www.youtube.com/channel/UCJxj2Ur6kQNf36VCQ2dx4KQ" target="_blank" class="link"><mat-icon class="youtube" svgIcon="youtube"></mat-icon></a>
  </div>
</div>
