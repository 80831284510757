import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Customer } from '../../lib/model/Customer';
import { Item } from '../../lib/model/Item';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

@Component({
  selector: 'app-cart-panel',
  templateUrl: './cart-panel.component.html',
  styleUrls: ['./cart-panel.component.scss']
})
export class CartPanelComponent implements OnInit {
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public sharedVariablesService: SharedVariablesService,
              private router: Router,
              private authenticationService: AuthenticationService,
              private ngxLoaderService: NgxUiLoaderService,
              private transactionService: TransactionService) { }

  ngOnInit() {
    if (this.sharedVariablesService.transaction) {
      this.transactionService.getTransaction(this.sharedVariablesService.transaction.id).toPromise().then((result) => {
        this.sharedVariablesService.transaction = result;
      });
    }
  }

  closePanel() {
    this.toggle.emit(false);
  }

  emptyCart() {
    this.ngxLoaderService.start();
    this.sharedVariablesService.transaction.removeAllItems();
    this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction).toPromise().then((transaction) => {
      this.sharedVariablesService.transaction = transaction;
      this.ngxLoaderService.stop();
    });
  }

  deleteFromCart(index: number) {
    this.ngxLoaderService.start();
    this.sharedVariablesService.transaction.removeItemAtIndex(index);
    this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction).toPromise().then((transaction) => {
      this.sharedVariablesService.transaction = transaction;
      this.ngxLoaderService.stop();
    });
  }

  pay() {
    if (this.authenticationService.getCurrentUser()) {
      this.sharedVariablesService.transaction.customer = this.authenticationService.getCurrentUser() as Customer;

      this.ngxLoaderService.start();
      this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction).toPromise().then((transaction) => {
        this.sharedVariablesService.transaction = transaction;
        this.ngxLoaderService.stop();
        this.closePanel();
        this.router.navigate(['/recapitulatif']);
      });
    } else {
      this.closePanel();
      this.router.navigate(['/finaliser-ma-commande']);
    }
  }
}
