import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../lib/services/customer.service';

@Component({
  selector: 'app-define-new-password',
  templateUrl: './define-new-password.component.html',
  styleUrls: ['./define-new-password.component.scss']
})
export class DefineNewPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public passwordMatchError: boolean = false;
  public passwordMatchSuccess: boolean = false;
  private email: string;
  private validationCode: string;

  constructor(private customerService: CustomerService,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar,
              private router: Router) {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl(undefined, Validators.compose([Validators.minLength(8), Validators.required])),
      passwordConfirmation: new FormControl(undefined, Validators.compose([Validators.minLength(8), Validators.required])),
    });
  }

  ngOnInit(): void {
    this.email = this.route.snapshot.paramMap.get('email');
    this.validationCode = this.route.snapshot.paramMap.get('validationCode');
  }

  checkPasswordsMatch() {
    if (this.resetPasswordForm.get('password').value.length > 0 && this.resetPasswordForm.get('passwordConfirmation').value.length > 0 ) {
      this.passwordMatchError = this.resetPasswordForm.get('password').value != this.resetPasswordForm.get('passwordConfirmation').value;
      this.passwordMatchSuccess = this.resetPasswordForm.get('password').value == this.resetPasswordForm.get('passwordConfirmation').value;
    }
  }

  submit() {
    if (this.resetPasswordForm.valid && this.passwordMatchSuccess) {
      this.customerService.resetPassword(this.email, this.validationCode, this.resetPasswordForm.get('password').value).toPromise().then((result) => {
        this.snackbar.open(('Votre mot de passe a bien été réinitialisé. Vous pouvez désormais vous connecter avec vos identifiants.'), undefined, {
          panelClass: ['success-snackbar'],
          duration: 5000,
        });
        this.router.navigate(['/'], { queryParams: { showLogin: 1 }});
      });
    }
  }
}
