<div class="section">
  <div class="carousel container" *ngIf="carouselAdvertisementsImages.length > 0">
    <slideshow [minHeight]="'178px'"
               [autoPlay]="true"
               [showArrows]="false"
               [showDots]="false"
               [autoPlayInterval]="6000"
               [backgroundSize]="'cover'"
               [imageUrls]="carouselAdvertisementsImages"
               [lazyLoad]="carouselAdvertisementsImages?.length > 1"
               [autoPlayWaitForLazyLoad]="true">
    </slideshow>
  </div>
  <div class="text-center container">
    <h2>Sélectionnez votre univers</h2>
    <div class="row mb40">
      <div class="col-xs-6 col-md-4" *ngFor="let category of categories">
        <div class="category" [routerLink]="['/produits/', category.code]">
          <img [src]="category.pictureUri" [alt]="category.name">
        </div>
      </div>
    </div>
  </div>
</div>
