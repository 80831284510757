import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Customer, CustomerAdapter } from './Customer';
import { Delivery } from './Delivery';
import { Item, ItemAdapter } from './Item';
import { Pack, PackAdapter } from './Pack';
import { Product } from './Product';
import { ProductVariant } from './ProductVariant';
import { Status } from './Status';
import { TransactionProperties, TransactionPropertiesAdapter } from './TransactionProperties';

export interface ItemsMapObject {
  // Product id -> ItemQuantity[]
  [productId: string]: ItemQuantity;
}

export interface ItemQuantity {
  // Ean -> quantity
  [ean: string]: number;
}

export interface ItemsWithOptionMapObject {
  [index: string]: Item;
}

export class Transaction {
  id: string;
  saleChannelCode: string;
  customer: Customer;
  properties: TransactionProperties;
  organisationId: string;
  sellerId: string;
  sellerDeviceId: string;
  totalAmount: number = 0;
  totalLinesDiscountAmount: number = 0;
  totalManualDiscountAmount: number = 0;
  totalDiscountAmount: number = 0;
  totalPurchasingAmount: number = 0;
  totalMarginRate: number = 0;
  totalSalesUnitCount: number = 0;
  totalDepositAmount: number = 0;
  totalItemQuantity: number = 0;
  packs: Pack[] = [];
  type: string;
  amount: string;
  validationDate: Date;
  currentStatus: Status;
  insufficientStock: boolean;
  delivery: Delivery;
  // Local only
  itemsWithOptions: ItemsWithOptionMapObject = {};
  customerId: string;
  private _items: Item[] = [];

  constructor(id: string,
              saleChannelCode: string,
              customer: Customer,
              properties: TransactionProperties,
              organisationId: string,
              sellerId: string,
              sellerDeviceId: string,
              totalAmount?: number,
              totalLinesDiscountAmount?: number,
              totalManualDiscountAmount?: number,
              totalDiscountAmount?: number,
              totalPurchasingAmount?: number,
              totalMarginRate?: number,
              totalSalesUnitCount?: number,
              totalDepositAmount?: number,
              totalItemQuantity?: number,
              items?: Item[],
              packs?: Pack[],
              type?: string,
              amount?: string,
              validationDate?: Date,
              currentStatus?: Status,
              insufficientStock: boolean = false,
              delivery?: Delivery) {
    this.id = id;
    this.saleChannelCode = saleChannelCode;
    this.customer = customer;
    this.properties = properties;
    this.organisationId = organisationId;
    this.sellerId = sellerId;
    this.sellerDeviceId = sellerDeviceId;
    this.totalAmount = totalAmount;
    this.totalLinesDiscountAmount = totalLinesDiscountAmount;
    this.totalManualDiscountAmount = totalManualDiscountAmount;
    this.totalDiscountAmount = totalDiscountAmount;
    this.totalPurchasingAmount = totalPurchasingAmount;
    this.totalMarginRate = totalMarginRate;
    this.totalSalesUnitCount = totalSalesUnitCount;
    this.totalDepositAmount = totalDepositAmount;
    this.totalItemQuantity = totalItemQuantity;
    this._items = items;
    this.packs = packs;
    this.type = type;
    this.amount = amount;
    this.validationDate = validationDate;
    this.currentStatus = currentStatus;
    this.insufficientStock = insufficientStock;
    this.delivery = delivery;
  }

  get items(): Item[] {
    return this._items;
  }

  public prepareForJSON(): any {
    let object = this;
    const target = {};
    const itemAdapter: ItemAdapter = new ItemAdapter();
    const customerAdapter: CustomerAdapter = new CustomerAdapter();

    // We prepare and clean transaction
    Object.assign(target, object);
    if (object.customer) {
      target['customer'] = customerAdapter.prepare(object.customer);
    }
    target['items'] = target['_items'].map((elt) => itemAdapter.prepare(elt));

    return target;
  }

  removeItemAtIndex(i: number) {
    this.items.splice(i, 1);
  }

  removeAllItems() {
    this._items = [];
  }
}

@Injectable({
  providedIn: 'root',
})
export class TransactionAdapter implements Adapter<Transaction> {
  adapt(item: any): Transaction {
    const transactionPropertiesAdapter = new TransactionPropertiesAdapter();
    const customerAdapter = new CustomerAdapter();
    const itemAdapter = new ItemAdapter();
    const packAdapter = new PackAdapter();

    return new Transaction(
      item.id,
      item.saleChannelCode ? item.saleChannelCode : (item.saleChannel ? item.saleChannel.code : undefined),
      item.customer ? customerAdapter.adapt(item.customer) : undefined,
      transactionPropertiesAdapter.adapt(item.properties),
      item.organisationId,
      item.sellerId,
      item.sellerDeviceId,
      item.totalAmount,
      item.totalLinesDiscountAmount,
      item.totalManualDiscountAmount,
      item.totalDiscountAmount,
      item.totalPurchasingAmount,
      item.totalMarginRate,
      item.totalSalesUnitCount,
      item.totalDepositAmount,
      item.totalItemQuantity,
      item.items ? item.items.map(elt => itemAdapter.adapt(elt)) : [],
      item.packs ? item.packs.map(elt => packAdapter.adapt(elt)) : [],
      item.type,
      item.amount,
      item.validationDate ? new Date(item.validationDate) : undefined,
      item.currentStatus,
      item.insufficientStock,
      item.delivery,
    );
  }

  prepare(object: Transaction): any {
    const target = {};
    const itemAdapter: ItemAdapter = new ItemAdapter();
    const customerAdapter: CustomerAdapter = new CustomerAdapter();

    // We prepare and clean transaction
    Object.assign(target, object);
    if (object.validationDate) {
      target['validationDate'] = target['validationDate'].toISOString();
    }
    if (!object.customerId && object.customer) {
      target['customer'] = customerAdapter.prepare(object.customer);
    } else {
      delete target['customer'];
    }
    target['items'] = object.items ? object.items.map(elt => itemAdapter.prepare(elt)) : [];
    delete target['_items'];
    delete target['packs'];
    delete target['productIds'];

    return target;
  }
}
