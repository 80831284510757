import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { ErrorService } from './lib/services/error.service';
import { SharedVariablesService } from './lib/services/shared-variables.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fraich-connection';
  private errorSubscription: Subscription;

  constructor(private titleService: Title,
              private router: Router,
              private errorService: ErrorService,
              private snackBar: MatSnackBar,
              public sharedVariablesService: SharedVariablesService) {

  }

  ngOnInit() {
    const self = this;
    this.errorSubscription = this.errorService.error.subscribe((message) => {
      self.snackBar.open(message, undefined, {
        panelClass: ['error-snackbar'],
      });
    });
  }

  ngOnDestroy() {
    this.errorSubscription.unsubscribe();
  }
}
