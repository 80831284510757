<mat-accordion *ngIf="orders">
  <mat-expansion-panel *ngFor="let order of orders" class="orders">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong class="primary">{{ order.creationDate | date: 'dd/MM/yyyy' }}</strong>
      </mat-panel-title>
      <mat-panel-description>
        <div class="hidden-xs">N° {{ order.id }}</div>
        <div class="hidden-xs">{{ order.lines.length }} article(s)</div>
        <div><strong class="accent">{{ order.totalAmount | price }}</strong></div>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="visible-xs">N° {{ order.id }}</div>
    <div class="visible-xs">{{ order.lines.length }} article(s)</div>

    <div class="articles mt20">
      <div class="item" *ngFor="let line of order.lines; let i = index;">
        <div class="item-image" *ngIf="line.defaultImage">
          <img [src]="line.defaultImage" [alt]="line.name">
        </div>
        <div class="item-description">
          <div class="item-name">{{ line.name }}</div>
          <div class="item-quantity">Quantité : {{ line.quantityWithCoefficient }} {{ line.salesUnitLabel }}</div>
          <div class="item-location">Casier n°{{ line.locationId | locationNumber }}</div>
        </div>
        <div class="item-price">
          <span>{{ line.totalAmount | price }}</span>
        </div>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>
