import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { ProductImage, ProductImageAdapter, ProductImageType } from './ProductImage';

const DEFAULT_IMAGE_URL = 'https://app.keyneosoft.io/assets/img/no-image.png';

export class RelatedProduct {
  id: string;
  name: string;
  description?: string;
  images?: ProductImage[] = [];
  tags?: string[];
  icons?: string[];
  defaultImage: ProductImage = undefined;

  constructor(id: string, name: string, description: string, images: ProductImage[], tags: string[], icons: string[]) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.images = images;
    this.tags = tags;
    this.icons = icons;
    this.defaultImage = this.getDefaultImage();
  }

  private getDefaultImage(): ProductImage {
    let haveDefaultImage: number = 0;
    let defaultImg: ProductImage;

    if (this.images !== undefined && this.images.length > 0) {
      this.images.forEach(image => {
        if (image && image.name === 'default') {
          haveDefaultImage += 1;
          defaultImg = image;
        }

        if (haveDefaultImage === 0) {
          defaultImg = this.images[0];
        }
      });
      return defaultImg;
    } else {
      return new ProductImage('', ProductImageType.IMAGE, 'generatedDefault', DEFAULT_IMAGE_URL, 0);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class RelatedProductAdapter implements Adapter<RelatedProduct> {

  adapt(item: any): RelatedProduct {
    const productImageAdapter: ProductImageAdapter = new ProductImageAdapter();

    return new RelatedProduct(
      item.id,
      item.name,
      item.description,
      item.images ? item.images.map((e) => {
        e = productImageAdapter.adapt(e);
        return e;
      }) : undefined,
      item.tags,
      item.icons
    );
  }
}
