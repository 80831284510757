import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Article } from '../../lib/model/Article';
import { Product } from '../../lib/model/Product';

@Component({
  selector: 'app-select-locker-modal',
  templateUrl: './select-locker-modal.component.html',
  styleUrls: ['./select-locker-modal.component.scss']
})
export class SelectLockerModalComponent implements OnInit {
  articles: Article[] = [];
  product: Product;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<SelectLockerModalComponent>) {

    if (data && data.articles) {
      this.articles = data.articles;
    }
    if (data && data.product) {
      this.product = data.product;
    }
  }

  ngOnInit() {
  }

  articleSelected() {
    this.dialogRef.close();
  }
}
