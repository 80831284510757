<div class="section container">
  <div class="float-right mt30"><button mat-stroked-button class="small" (click)="logout()"><mat-icon class="font-size">exit_to_app</mat-icon> Déconnexion</button></div>
  <h1>Mon compte</h1>

  <mat-tab-group class="mb20" animationDuration="0">
    <mat-tab label="Mes informations">
      <div class="customer-info" *ngIf="customer && !editCustomer">
        <div class="row">
          <div class="col-md-6">
            <h4 class="accent">Informations obligatoires :</h4>
            {{ customer.civility }} {{ customer.firstName }} {{ customer.lastName }}<br>
            {{ customer.email }}<br>
            {{ customer.phone | mask: '00 00 00 00 00'}}<br><br>

            <h4 class="accent">Newsletter :</h4>
            <p *ngIf="customer.optins && customer.optins['advertising']">Vous recevez la newsletter</p>
            <p *ngIf="!customer.optins || !customer.optins['advertising']">Vous ne recevez pas la newsletter</p>
          </div>

          <div class="col-md-6">
            <h4 class="accent">Adresse :</h4>
            <div *ngIf="customer.address">
              {{ customer.address.line1 }}<br>
              {{ customer.address.postalCode }} {{ customer.address.city }}<br>
            </div>
            <div *ngIf="!customer.address"><i>Non renseignée</i></div>
            <br>
            <h4 class="accent">Date de naissance :</h4>
            <span *ngIf="customer.birthDay">{{ customer.birthDay.toLocaleDateString() }}</span>
            <i *ngIf="!customer.birthDay">Non renseignée</i>
          </div>
        </div>
        <div class="text-center mt10 mb10">
          <button mat-flat-button color="primary" (click)="toggleEditCustomer(undefined)">Modifier</button>
        </div>
      </div>
      <app-customer-form (customerUpdated)="toggleEditCustomer($event)" *ngIf="editCustomer" [accountPage]="true" (cancel)="toggleEditCustomer(undefined)"></app-customer-form>
    </mat-tab>
    <mat-tab label="Mes commandes">
      <app-customer-orders [orders]="orders"></app-customer-orders>
      <div class="mt20 mb10 text-center" *ngIf="!allOrdersLoaded && orders.length > 0">
        <button mat-stroked-button color="primary" (click)="loadOrders()">Charger plus de commandes</button>
      </div>
      <div *ngIf="orders.length === 0">
        Vous n'avez aucune commande enregistrée pour l'instant.
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
