import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { SlideshowModule } from 'ng-simple-slideshow';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClickOutsideDirective } from './lib/directives/click-outside.directive';
import { AuthInterceptor } from './lib/helpers/auth.interceptor';
import { ErrorInterceptor } from './lib/helpers/error.interceptor';
import { CustomReuseStrategy } from './lib/helpers/routing';
import { LibModule } from './lib/lib.module';
import localFr from '@angular/common/locales/fr';
import { AuthenticationService } from './lib/services/authentication.service';
import { SharedVariablesService } from './lib/services/shared-variables.service';
import { CustomerFormComponent } from './partials/customer-form/customer-form.component';
import { HeaderComponent } from './partials/header/header.component';
import { FooterComponent } from './partials/footer/footer.component';
import { HomeComponent } from './pages/home/home.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { ProductsComponent } from './pages/products/products.component';
import { CategoryTreeComponent } from './partials/category-tree/category-tree.component';
import { LoginFormComponent } from './partials/login-form/login-form.component';
import { MenuComponent } from './partials/menu/menu.component';
import { ResetPasswordComponent } from './partials/reset-password/reset-password.component';
import { SelectLockerModalComponent } from './partials/select-locker-modal/select-locker-modal.component';
import { LockerContentComponent } from './partials/select-locker-modal/locker-content/locker-content.component';
import { CartPanelComponent } from './partials/cart-panel/cart-panel.component';
import { ProductInformationsComponent } from './partials/product-informations/product-informations.component';
import { FinalizeComponent } from './pages/finalize/finalize.component';
import { NoAccountComponent } from './partials/no-account/no-account.component';
import { RecapComponent } from './pages/recap/recap.component';
import { SuccessComponent } from './pages/success/success.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { CustomerOrdersComponent } from './partials/customer-orders/customer-orders.component';
import { GoUpComponent } from './partials/go-up/go-up.component';
import { CreateAccountComponent } from './pages/create-account/create-account.component';
import { DefineNewPasswordComponent } from './pages/define-new-password/define-new-password.component';

// Ngx mask
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// Mat form fields appearance
const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

// Configure loader
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#515151',
  bgsOpacity: 0.5,
  bgsPosition: 'center-center',
  bgsSize: 90,
  bgsType: 'fading-circle',
  blur: 4,
  delay: 0,
  fgsColor: '#e65e0a',
  fgsPosition: 'center-center',
  fgsSize: 90,
  fgsType: 'fading-circle',
  gap: 24,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(255,255,255,0.5)',
  pbColor: '#515151',
  pbDirection: 'ltr',
  pbThickness: 4,
  hasProgressBar: false,
  text: '',
  textColor: '#515151',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 250
};

// Register the used locale file on your own.
registerLocaleData(localFr);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ClickOutsideDirective,
    HomeComponent,
    CategoriesComponent,
    ProductsComponent,
    CategoryTreeComponent,
    MenuComponent,
    SelectLockerModalComponent,
    LockerContentComponent,
    CartPanelComponent,
    ProductInformationsComponent,
    FinalizeComponent,
    LoginFormComponent,
    CustomerFormComponent,
    ResetPasswordComponent,
    NoAccountComponent,
    RecapComponent,
    SuccessComponent,
    MyAccountComponent,
    CustomerOrdersComponent,
    GoUpComponent,
    CreateAccountComponent,
    DefineNewPasswordComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    LibModule,
    MatSnackBarModule,
    MatBadgeModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatSelectModule,
    MatExpansionModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatTreeModule,
    MatDialogModule,
    MatRadioModule,
    MatCheckboxModule,
    NgxMaskModule.forRoot(),
    MatAutocompleteModule,
    MatTabsModule,
    SlideshowModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [SharedVariablesService, AuthenticationService]},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: {}},
    {provide: RouteReuseStrategy, useClass: CustomReuseStrategy},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance},
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SelectLockerModalComponent,
    ProductInformationsComponent]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry,
              private domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom.svg'));
  }
}
