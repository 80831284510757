import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentRequestPage, PaymentRequestPageAdapter } from '../model/PaymentRequestPage';
import { PaymentRequestUrls } from '../model/PaymentRequestUrls';
import { Transaction } from '../model/Transaction';
import { ApiService } from './api.service';
import { SharedVariablesService } from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends ApiService {

  constructor(private http: HttpClient,
              private sharedVariablesService: SharedVariablesService,
              private paymentRequestPageAdapter: PaymentRequestPageAdapter) {
    super();
  }

  getPayzenPaymentRequestPage(paymentRequestUrls: PaymentRequestUrls, transaction: Transaction): Observable<PaymentRequestPage> {
    return this.http.put<PaymentRequestPage>(this.baseUri + 'payzen/paymentRequest/' + transaction.id, paymentRequestUrls)
      .pipe(map(item => this.paymentRequestPageAdapter.adapt(item)));
  }

  getStripePaymentRequestPage(paymentRequestUrls: PaymentRequestUrls, transaction: Transaction): Observable<PaymentRequestPage> {
    return this.http.put<PaymentRequestPage>(this.baseUri + 'stripe/paymentRequest/' + transaction.id, paymentRequestUrls)
      .pipe(map(item => this.paymentRequestPageAdapter.adapt(item)));
  }

  getSogecommercePaymentRequestPage(paymentRequestUrls: PaymentRequestUrls, transaction: Transaction): Observable<PaymentRequestPage> {
    return this.http.put<PaymentRequestPage>(this.baseUri + 'sogecommerce/paymentRequest/' + transaction.id, paymentRequestUrls)
      .pipe(map(item => this.paymentRequestPageAdapter.adapt(item)));
  }
}
