import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Category, CategoryAdapter } from '../model/Category';
import { SaleChannel } from '../model/SaleChannel';
import { ApiService } from './api.service';
import {SharedVariablesService} from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends ApiService {

  constructor(private http: HttpClient,
              private adapter: CategoryAdapter,
              private sharedVariablesService: SharedVariablesService) {
    super();
  }

  /**
   * Returns categories.
   * @param level
   */
  public getCategories(level: number = 1): Observable<Category[]> {
    let params = new HttpParams();

    if (this.sharedVariablesService.saleChannel) {
      params = params.set('saleChannelCode', this.sharedVariablesService.saleChannel.code);
    }

    params = params.set('level', level.toString());

    return this.http.get<Category[]>(this.baseUri + 'categories/', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  /**
   * Returns categories tree.
   */
  public getCategoriesTree(): Observable<Category[]> {
    let params = new HttpParams();
    if (this.sharedVariablesService.saleChannel) {
      params = params.set('saleChannelCode', this.sharedVariablesService.saleChannel.code)
                     .set('useCustomFilters', 'true');
    }

    return this.http.get<Category[]>(this.baseUri + 'categories/tree', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  /**
   * Return category form its code.
   * @param categoryCode
   */
  getCategoryFromCode(categoryCode: string): Observable<Category> {
    let params = new HttpParams();

    if (this.sharedVariablesService.saleChannel) {
      params = params.set('saleChannelCode', this.sharedVariablesService.saleChannel.code)
                     .set('useCustomFilters', 'true');
    }

    return this.http.get<Category>(this.baseUri + 'categories/tree/' + categoryCode, {params}).pipe(
      (map(item => this.adapter.adapt(item)))
    );
  }
}
