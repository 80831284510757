<div class="section container">
  <h1>Finalisez votre commande <img class="poulette" src="/assets/images/poulette.svg"  alt=""></h1>

  <h2 class="mt50">Vous avez 24h pour aller chercher votre commande, une fois le paiement validé</h2>

  <div class="row">
    <div class="col-xs-12 col-md-4">
      <div class="summary">
        <img class="picto" src="/assets/images/borne.svg" alt="">
        <h2>Votre Fraîch' Village</h2>
        <p>{{ sharedVariablesService.organisation.name }}<br>
          {{ sharedVariablesService.organisation.address.addressLine1 }}<br>
          {{ sharedVariablesService.organisation.address.postal }} {{ sharedVariablesService.organisation.address.city }}<br>
        </p>
        <p class="limit">Retrait avant le {{ limit | date: "short"}}</p>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="summary">
        <img class="picto" src="/assets/icons/shopping-cart-full.svg" alt="">
        <h2>Votre panier</h2>
        <p class="sm">{{ sharedVariablesService.transaction.items.length }} produit{{ sharedVariablesService.transaction.items.length > 1 ? 's' : '' }}</p>
        <p class="sm">{{ sharedVariablesService.transaction.totalAmount | price }}</p>
      </div>
    </div>
    <div class="col-xs-12 col-md-4">
      <div class="summary">
        <img class="picto" src="/assets/images/account.svg" alt="">
        <h2 *ngIf="sharedVariablesService.transaction.customer.firstName">Votre compte</h2>
        <h2 *ngIf="!sharedVariablesService.transaction.customer.firstName">Vos informations</h2>
        <p class="sm" *ngIf="sharedVariablesService.transaction.customer.firstName || sharedVariablesService.transaction.customer.lastName">{{ sharedVariablesService.transaction.customer.firstName }} {{ sharedVariablesService.transaction.customer.lastName }}</p>
        <p class="sm mb5">{{ sharedVariablesService.transaction.customer.email }}</p>
        <p class="sm" *ngIf="sharedVariablesService.transaction.customer.phone">{{ sharedVariablesService.transaction.customer.phone | mask: '00 00 00 00 00'}}</p>
      </div>
    </div>
  </div>
  <div class="mt20 mb50">
    <form [formGroup]="consent" (ngSubmit)="processPayment()">
      <mat-checkbox formControlName="delay" color="primary" value="true">J’ai compris que si mes courses ne sont pas retirées sous 24H, ma commande est annulée et non remboursée *</mat-checkbox>
      <br>
      <mat-checkbox formControlName="generalTermsOfUse" color="primary" value="true">J’accepte les <a href="https://img.keyneosoft.io/fraichconnection/public/pdf/CGV.pdf" target="_blank">conditions générales de vente</a> *</mat-checkbox>
      <div class="text-center mt20 mb20">
        <div>
          <div class="error" *ngIf="status">
            <div *ngIf="status && status === 'error'"><mat-icon class="font-size mr5">warning</mat-icon> <strong>Une erreur s'est produite lors du paiement. Veuillez réessayer.</strong></div>
            <div *ngIf="status && status === 'refused'"><mat-icon class="font-size mr5">warning</mat-icon> <strong>Le paiement a été refusé. Veuillez réessayer.</strong></div>
            <div *ngIf="status && status === 'cancelled'"><mat-icon class="font-size mr5">warning</mat-icon> <strong>Vous avez annulé le paiement. Veuillez réessayer.</strong></div>
          </div>
        </div>
        <button mat-flat-button color="primary" [disabled]="!consent.valid">Payer ma commande</button>
      </div>
    </form>
  </div>
</div>
