import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Organisation, OrganisationAdapter } from '../model/Organisation';
import { ApiService } from './api.service';
import {SharedVariablesService} from './shared-variables.service';

@Injectable()
export class OrganisationService extends ApiService {
  constructor(private http: HttpClient,
              private adapter: OrganisationAdapter,
              private sharedVariablesService: SharedVariablesService) {
    super();
  }

  getOrganisation(organisationId: string): Observable<Organisation> {
    const params = new HttpParams();
    return this.http.get<Organisation>(this.baseUri + 'organisations/' + organisationId, {params})
      .pipe(map(item => this.adapter.adapt(item)));
  }

  getOrganisationListBySaleChannelCode(saleChannelCode: string, sortBy?: string, sortOrder: string = 'ASC') {
    let params = new HttpParams();
    params = params.set('saleChannels', saleChannelCode)
      .set('types', 'OUTLET')
      .set('excludeInactive', 'true');

    if (this.sharedVariablesService.saleChannel) {
      params = params.set('saleChannels', this.sharedVariablesService.saleChannel.code);
    }

    if (sortBy) {
      params = params.set('sort', sortBy + ',' + sortOrder);
    }

    return this.http.get<Organisation[]>(this.baseUri + 'organisations', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  searchNearestOrganisations(types: string[], address: string, limit: number = 2) {
    let params = new HttpParams();

    params = params.set('limit', limit.toString())
                   .set('excludeInactive', 'true');

    if (types.length > 0) {
      params = params.set('types', types.toString());
    }

    return this.http.get<Organisation[]>(this.baseUri + 'organisations/nearest/' + encodeURIComponent(address), {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  searchOrganisations(types: string[], postalCode = undefined, latitude: number, longitude: number, limit: number = 2) {
    let params = new HttpParams();

    params = params.set('limit', limit.toString())
                   .set('excludeInactive', 'true');

    if (types.length > 0) {
      params = params.set('types', types.toString());
    }
    if (postalCode) {
      params = params.set('postalCode', postalCode);
    }
    if (latitude && longitude) {
      params = params.set('locationY', latitude.toString());
      params = params.set('locationX', longitude.toString());
    }

    return this.http.get<Organisation[]>(this.baseUri + 'organisations/search', {params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }
}
