import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format price with superior €cents by inserting HTML.
 */
@Pipe({
  name: 'price',
})
export class PricePipe implements PipeTransform {
  constructor() {
  }

  transform(priceA: number) {
    if (isNaN(priceA)) {
      return '-';
    }
    const currency = 'EUR';

    return new Intl.NumberFormat('fr', {style: 'currency', currency}).format(priceA);
  }
}
