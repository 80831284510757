import { Component, Input, OnInit } from '@angular/core';
import { Category } from '../../lib/model/Category';
import { CategoryService } from '../../lib/services/category.service';

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss']
})
export class CategoryTreeComponent implements OnInit {
  @Input() category: Category;
  public categories: Category[];

  constructor(private categoryService: CategoryService) { }

  ngOnInit() {
    this.categoryService.getCategoriesTree().toPromise().then((categories) => {
      this.categories = categories;
    });
  }

}
