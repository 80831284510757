import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Category } from '../../lib/model/Category';
import { CategoryService } from '../../lib/services/category.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  public treeControl = new NestedTreeControl<Category>(node => node.children);
  public dataSource = new MatTreeNestedDataSource<Category>();
  private saleChannelChangedSubscription: Subscription;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private router: Router,
              public sharedVariablesService: SharedVariablesService,
              private categoryService: CategoryService) {
    this.categoryService.getCategoriesTree().toPromise().then((categories) => {
      this.dataSource.data = categories;
    });
  }

  ngOnInit() {
    const self = this;
  }

  ngOnDestroy() {
    this.saleChannelChangedSubscription.unsubscribe();
  }

  hasChild = (_: number, node: Category) => !!node.children && node.children.length > 0;

  closeMenu() {
    this.toggle.emit(false);
  }
}
