<app-header></app-header>
<router-outlet></router-outlet>
<app-footer></app-footer>
<ngx-ui-loader [fgsTemplate]="foregroundSpinner"></ngx-ui-loader>
<app-go-up></app-go-up>
<ng-template #foregroundSpinner>
  <svg class="loader" xmlns="http://www.w3.org/2000/svg" width="200" height="90" viewBox="0 0 70 32.18">
    <g id="brouette" data-name="Groupe 292" transform="translate(0)">
      <g id="Groupe_283" data-name="Groupe 283" transform="translate(52.439 14.556)">
        <g id="Groupe 280" data-name="Groupe 280" transform="translate(1.45 1.479)">
          <g id="roue" data-name="Groupe 279" transform="translate(0)">
            <g id="Groupe_245" data-name="Groupe 245" transform="translate(6.632)">
              <g id="Groupe_239" data-name="Groupe 239" transform="translate(0)">
                <g id="Groupe_236" data-name="Groupe 236" transform="translate(0.223)">
                  <g id="Groupe_235" data-name="Groupe 235">
                    <path id="Tracé_783" data-name="Tracé 783" d="M152.695,304.419h.95l-.116-4.721h-.718Z" transform="translate(-152.695 -299.698)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_238" data-name="Groupe 238" transform="translate(0 3.902)">
                  <g id="Groupe_237" data-name="Groupe 237">
                    <rect id="Rectangle_106" data-name="Rectangle 106" width="1.397" height="0.811" rx="0.405" fill="#b37d52"/>
                  </g>
                </g>
              </g>
              <g id="Groupe_244" data-name="Groupe 244" transform="translate(0 9.889)">
                <g id="Groupe_241" data-name="Groupe 241" transform="translate(0.223 0.05)">
                  <g id="Groupe_240" data-name="Groupe 240">
                    <path id="Tracé_784" data-name="Tracé 784" d="M153.645,354.544h-.95l.116,4.721h.718Z" transform="translate(-152.695 -354.544)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_243" data-name="Groupe 243">
                  <g id="Groupe_242" data-name="Groupe 242">
                    <rect id="Rectangle_107" data-name="Rectangle 107" width="1.397" height="0.811" rx="0.405" fill="#b37d52"/>
                  </g>
                </g>
              </g>
            </g>
            <g id="Groupe_256" data-name="Groupe 256" transform="translate(1.893 1.893)">
              <g id="Groupe_250" data-name="Groupe 250" transform="translate(6.794)">
                <g id="Groupe_247" data-name="Groupe 247" transform="translate(0.152)">
                  <g id="Groupe_246" data-name="Groupe 246">
                    <path id="Tracé_785" data-name="Tracé 785" d="M125.313,313.4l.672.672,3.257-3.421-.508-.508Z" transform="translate(-125.313 -310.144)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_249" data-name="Groupe 249" transform="translate(0 2.52)">
                  <g id="Groupe_248" data-name="Groupe 248" transform="translate(0 0)">
                    <rect id="Rectangle_108" data-name="Rectangle 108" width="1.397" height="0.811" rx="0.405" transform="translate(0.987 1.561) rotate(-135)" fill="#b37d52"/>
                  </g>
                </g>
              </g>
              <g id="Groupe_255" data-name="Groupe 255" transform="translate(0 6.753)">
                <g id="Groupe_252" data-name="Groupe 252" transform="translate(0 0.193)">
                  <g id="Groupe_251" data-name="Groupe 251">
                    <path id="Tracé_786" data-name="Tracé 786" d="M167.571,349.146l-.672-.672-3.257,3.421.508.508Z" transform="translate(-163.643 -348.474)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_254" data-name="Groupe 254" transform="translate(2.561 0)">
                  <g id="Groupe_253" data-name="Groupe 253" transform="translate(0)">
                    <rect id="Rectangle_109" data-name="Rectangle 109" width="1.397" height="0.811" rx="0.405" transform="translate(0.987 1.561) rotate(-135)" fill="#b37d52"/>
                  </g>
                </g>
              </g>
            </g>
            <g id="Groupe_267" data-name="Groupe 267" transform="translate(0 6.632)">
              <g id="Groupe_261" data-name="Groupe 261" transform="translate(9.939)">
                <g id="Groupe_258" data-name="Groupe 258" transform="translate(0 0.223)">
                  <g id="Groupe_257" data-name="Groupe 257">
                    <path id="Tracé_787" data-name="Tracé 787" d="M114.867,337.525v.95l4.721-.116v-.718Z" transform="translate(-114.867 -337.525)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_260" data-name="Groupe 260" transform="translate(0.008)">
                  <g id="Groupe_259" data-name="Groupe 259">
                    <rect id="Rectangle_110" data-name="Rectangle 110" width="0.811" height="1.397" rx="0.405" fill="#b37d52"/>
                  </g>
                </g>
              </g>
              <g id="Groupe_266" data-name="Groupe 266" transform="translate(0)">
                <g id="Groupe_263" data-name="Groupe 263" transform="translate(0 0.223)">
                  <g id="Groupe_262" data-name="Groupe 262">
                    <path id="Tracé_788" data-name="Tracé 788" d="M174.435,338.476v-.95l-4.721.116v.718Z" transform="translate(-169.714 -337.525)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_265" data-name="Groupe 265" transform="translate(3.961)">
                  <g id="Groupe_264" data-name="Groupe 264" transform="translate(0)">
                    <rect id="Rectangle_111" data-name="Rectangle 111" width="0.811" height="1.397" rx="0.405" fill="#b37d52"/>
                  </g>
                </g>
              </g>
            </g>
            <g id="Groupe_278" data-name="Groupe 278" transform="translate(1.893 1.893)">
              <g id="Groupe_272" data-name="Groupe 272" transform="translate(6.794 6.794)">
                <g id="Groupe_269" data-name="Groupe 269" transform="translate(0.152 0.152)">
                  <g id="Groupe_268" data-name="Groupe 268">
                    <path id="Tracé_789" data-name="Tracé 789" d="M125.985,348.474l-.672.672,3.421,3.257.508-.508Z" transform="translate(-125.313 -348.474)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_271" data-name="Groupe 271" transform="translate(0 0)">
                  <g id="Groupe_270" data-name="Groupe 270">
                    <rect id="Rectangle_112" data-name="Rectangle 112" width="0.811" height="1.397" rx="0.405" transform="translate(0.573 1.561) rotate(-135)" fill="#b37d52"/>
                  </g>
                </g>
              </g>
              <g id="Groupe_277" data-name="Groupe 277" transform="translate(0)">
                <g id="Groupe_274" data-name="Groupe 274" transform="translate(0)">
                  <g id="Groupe_273" data-name="Groupe 273">
                    <path id="Tracé_790" data-name="Tracé 790" d="M166.9,314.072l.672-.672-3.421-3.256-.508.508Z" transform="translate(-163.643 -310.144)" fill="#ceb099"/>
                  </g>
                </g>
                <g id="Groupe_276" data-name="Groupe 276" transform="translate(2.561 2.561)">
                  <g id="Groupe_275" data-name="Groupe 275" transform="translate(0 0)">
                    <rect id="Rectangle_113" data-name="Rectangle 113" width="0.811" height="1.397" rx="0.405" transform="translate(0.573 1.561) rotate(-135)" fill="#b37d52"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path id="Tracé_791" data-name="Tracé 791" d="M141.792,323.469a2.993,2.993,0,1,1-2.993,2.993A2.993,2.993,0,0,1,141.792,323.469Zm0,3.353a.359.359,0,1,0-.359-.359A.359.359,0,0,0,141.792,326.822Z" transform="translate(-134.462 -319.161)" fill="#986438"/>
        </g>
        <g id="Groupe_282" data-name="Groupe 282" transform="translate(0 0)">
          <g id="Groupe_281" data-name="Groupe 281">
            <path id="Tracé_792" data-name="Tracé 792" d="M115.646,309.1a8.78,8.78,0,1,0-8.78-8.78A8.79,8.79,0,0,0,115.646,309.1Zm0-16.111a7.331,7.331,0,1,1-7.331,7.331A7.339,7.339,0,0,1,115.646,292.986Z" transform="translate(-106.866 -291.536)" fill="#7f4b24"/>
          </g>
        </g>
      </g>
      <g id="Groupe_291" data-name="Groupe 291" transform="translate(0)">
        <g id="Groupe_288" data-name="Groupe 288" transform="translate(20.59)">
          <g id="Groupe_284" data-name="Groupe 284">
            <path id="Tracé_793" data-name="Tracé 793" d="M187.3,214.533l-.05-3.318,34.842,5.521-1.074,3.14Z" transform="translate(-187.252 -211.214)" fill="#986438"/>
          </g>
          <g id="Groupe_285" data-name="Groupe 285" transform="translate(0.05 3.318)">
            <path id="Tracé_794" data-name="Tracé 794" d="M225.82,238.009l-32.594-5.165-.05-3.318,33.718,5.343Z" transform="translate(-193.176 -229.526)" fill="#b37d52"/>
          </g>
          <g id="Groupe_286" data-name="Groupe 286" transform="translate(0.1 6.637)">
            <path id="Tracé_795" data-name="Tracé 795" d="M230.621,256.142l-31.471-4.986-.05-3.318L231.694,253Z" transform="translate(-199.1 -247.837)" fill="#986438"/>
          </g>
          <g id="Groupe_287" data-name="Groupe 287" transform="translate(0.151 9.955)">
            <path id="Tracé_796" data-name="Tracé 796" d="M235.421,274.275l-30.347-4.808-.05-3.318,31.471,4.986Z" transform="translate(-205.024 -266.148)" fill="#b37d52"/>
          </g>
        </g>
        <g id="Groupe_290" data-name="Groupe 290" transform="translate(0 10.93)">
          <path id="Tracé_797" data-name="Tracé 797" d="M377.183,300.816h0Z" transform="translate(-356.17 -295.508)" fill="none"/>
          <path id="Tracé_798" data-name="Tracé 798" d="M378.192,297.734v0Z" transform="translate(-357.362 -292.984)" fill="none"/>
          <path id="Tracé_799" data-name="Tracé 799" d="M211.169,287.423a1.53,1.53,0,0,1-.183-.556v-.014h0l-.03-1.991-.006-.4,30.347,4.809-.775,2.265a1.507,1.507,0,0,1-.365.5l-28.986-4.609Z" transform="translate(-190.157 -282.115)" fill="#986438"/>
          <path id="Tracé_800" data-name="Tracé 800" d="M378.193,297.67v0Z" transform="translate(-357.364 -292.932)" fill="#7f4b24"/>
          <path id="Tracé_801" data-name="Tracé 801" d="M377.184,297.747a1.53,1.53,0,0,0,.183.556A1.521,1.521,0,0,1,377.184,297.747Z" transform="translate(-356.354 -292.995)" fill="#7f4b24"/>
          <g id="Groupe_289" data-name="Groupe 289">
            <path id="Tracé_802" data-name="Tracé 802" d="M318.646,319.756v-6.3l5.434.864Z" transform="translate(-292.459 -305.859)" fill="none"/>
            <path id="Tracé_803" data-name="Tracé 803" d="M313.263,318.714l5.434-5.434,1.238.2-6.672,6.672v5.918h-1.45V312.186l1.45.231Z" transform="translate(-287.076 -304.817)" fill="#6f7070"/>
            <path id="Tracé_804" data-name="Tracé 804" d="M151.342,273.1c.329-2.156,3.446-1.47,3.446-1.47l17.326,2.643.03,1.991h0v.014a1.52,1.52,0,0,0,.183.556h0l3.725.592,1.45.231,6.7,1.065,1.238.2,15.873,2.524,11.335,1.8a.725.725,0,1,1-.228,1.432l-28.247-4.492-1.238-.2-5.434-.864-1.45-.23L172,278.249l-.025-.007-5.6-.854s-4.573-5.145-12.354-1.884C154.022,275.5,151.013,275.255,151.342,273.1Z" transform="translate(-151.317 -271.525)" fill="#7f4b24"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</ng-template>
