import { Component, OnInit } from '@angular/core';
import { IImage } from 'ng-simple-slideshow';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Advertisement } from '../../lib/model/Advertisement';
import { Category } from '../../lib/model/Category';
import { AdvertisementService } from '../../lib/services/advertisement.service';
import { CategoryService } from '../../lib/services/category.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  public categories: Category[] = [];
  public carouselAdvertisements: Advertisement[] = [];
  public carouselAdvertisementsImages: IImage[] = [];

  constructor(private sharedVariablesService: SharedVariablesService,
              private advertisementService: AdvertisementService,
              private categoryService: CategoryService,
              private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.ngxLoaderService.start('getCategories');

    this.categoryService.getCategories().toPromise().then((categories) => {
      this.categories = categories;
      this.ngxLoaderService.stop('getCategories');
    }, err => {
      this.ngxLoaderService.stop('getCategories');
    });

    this.ngxLoaderService.start('getAdvertisements');

    this.advertisementService.getAdvertisements('B2CWEB', 'CATEGORIES_BANNER').toPromise().then((advertisements) => {
      if (advertisements && advertisements.length > 0) {
        this.carouselAdvertisements = advertisements;
        this.carouselAdvertisements.forEach((ad) => {
          this.carouselAdvertisementsImages.push({
            href: ad.redirectionUrl,
            title: undefined,
            url: ad.mediaUrl})
        });
      }
      this.ngxLoaderService.stop('getAdvertisements');
    }, err => {
      this.ngxLoaderService.stop('getAdvertisements');
    });
  }

}
