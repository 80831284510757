<div class="cart-panel" [ngClass]="{ 'inPage': sharedVariablesService.cartInPageVisible }" *ngIf="sharedVariablesService.transaction">
  <div class="details">
    <img class="float-left brouette" src="/assets/icons/shopping-cart-full.svg">
    <div class="cart-header">
      <div class="title">Votre panier</div>
      <div class="amount">Total : {{ sharedVariablesService.transaction.totalAmount | price }}</div>
    </div>

    <div class="articles">
      <div class="item" *ngFor="let item of sharedVariablesService.transaction.items; let i = index;">
        <div class="item-image" *ngIf="item.defaultImage">
          <img [src]="item.defaultImage" [alt]="item.name">
        </div>
        <div class="item-description">
          <div class="item-name">{{ item.name }}</div>
          <div class="item-quantity">Quantité : {{ item.quantityWithCoefficient }} {{ item.salesUnitLabel }}</div>
          <div class="item-location">Casier n°{{ item.location?.businessId }}</div>
        </div>
        <div class="item-price">
          <span>{{ item.totalAmount | price }}</span> <button mat-icon-button class="delete-icon" (click)="deleteFromCart(i)"><mat-icon svgIcon="bin"></mat-icon></button>
        </div>
      </div>

      <div class="text-center mt5 mb10" *ngIf="sharedVariablesService.transaction.items.length > 0">
        <button mat-button class="empty-cart small" (click)="emptyCart()"><mat-icon class="font-size" svgIcon="bin"></mat-icon> Vider le panier</button>
      </div>
    </div>
  </div>
  <div class="cart-summary">
    <div class="text-center">
      <div class="total">Total : {{ sharedVariablesService.transaction.totalAmount | price }}</div>
      <div><button (click)="pay()" mat-flat-button class="mt20 full-width" color="primary">VALIDER & PAYER</button></div>
    </div>
  </div>
</div>
