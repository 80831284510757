<form [formGroup]="userForm" (ngSubmit)="submitUserForm()">
  <div>
    <mat-form-field class="full-width mb20">
      <mat-label>Email *</mat-label>
      <input matInput type="text" formControlName="email" autocomplete="off">
      <mat-hint>Cette adresse ne sera utilisée que dans le cadre de votre commande.</mat-hint>
    </mat-form-field>
  </div>
  <div>
    <mat-checkbox formControlName="advertising" color="primary" value="true">Je souhaite recevoir des communications de la part de Fraîch' Connection</mat-checkbox>
  </div>
  <div class="mt20">
    <div class="text-center">
      <button mat-flat-button color="primary" type="submit" [disabled]="!userForm.valid">Continuer</button>
    </div>
  </div>
</form>
