import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrderPage, OrderPageAdapter } from '../model/OrderPage';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService extends ApiService {
  readonly ORDER_API_URL: string = 'orders';

  constructor(private http: HttpClient,
              private orderPageAdapter: OrderPageAdapter) {
    super();
  }

  /**
   * Return orders linked with a customer id.
   */
  findOrdersForCustomerId(customerId: string, page: number = 0, size: number = 5): Observable<OrderPage> {
    let params = new HttpParams();

    params = params.set('page', page.toString())
                    .set('size', size.toString());

    return this.http.get<OrderPage>(this.baseUriNoAuthKey + this.ORDER_API_URL + '?customerId=' + customerId.replace(/\+/gi, '%2B'), {params}).pipe(map(item => this.orderPageAdapter.adapt(item)));
  }
}
