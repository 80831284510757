import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-go-up',
  templateUrl: './go-up.component.html',
  styleUrls: ['./go-up.component.scss']
})
export class GoUpComponent implements OnInit, OnDestroy {
  showGoUpArrow: boolean = false;

  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll.bind(this), true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * Scroll event.
   * @param $event
   */
  scroll($event) {
    this.showGoUpArrow = window.scrollY > 0;
  }

  /**
   * Go to the top of the page.
   */
  goUp() {
    window.scroll(0, 0);
  }
}
