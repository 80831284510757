<div class="village">
  <div class="section text-center">
    <div class="pt50 pb50">
      <div class="suptitle">Pour commencer</div>
      <h2>Sélectionnez votre Fraîch' Village</h2>
      <form [formGroup]="organisationForm" class="village-select" (ngSubmit)="selectOrganisation()">
        <mat-form-field floatLabel="always">
          <mat-label>Mon village</mat-label>
          <mat-select formControlName="organisation">
            <mat-option *ngFor="let organisation of organisations" [value]="organisation">{{ organisation.name }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button type="submit" mat-flat-button color="primary">
          OK
        </button>
      </form>
    </div>
  </div>
</div>
