import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { CustomerService } from '../../lib/services/customer.service';

class MyDialogComponent {
}

class DialogData {
  email: string = undefined;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  public resetPasswordForm: FormGroup;

  constructor(private customerService: CustomerService,
              private dialogRef: MatDialogRef<MyDialogComponent>,
              private snackbar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit() {
    this.resetPasswordForm = new FormGroup({
      email: new FormControl(this.data ? this.data.email : undefined, Validators.compose([Validators.required, Validators.email])),
    });
  }

  onSubmit() {
    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.customerService.requestResetPassword(this.resetPasswordForm.get('email').value).subscribe(() => {
      this.dialogRef.close();
      this.snackbar.open(('Un e-mail a été envoyé à ' + this.resetPasswordForm.get('email').value + '.'), undefined, {
        panelClass: ['success-snackbar'],
        duration: 2500,
      });
    }, error => {
    });
  }
}
