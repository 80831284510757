import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Transaction } from '../../lib/model/Transaction';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

@Component({
  selector: 'app-finalize',
  templateUrl: './finalize.component.html',
  styleUrls: ['./finalize.component.scss']
})
export class FinalizeComponent implements OnInit {
  userIdentified: boolean = false;
  showCustomerForm: boolean = false;
  showNoAccount: boolean = false;

  constructor(public router: Router,
              public sharedVariablesService: SharedVariablesService,
              private authenticationService: AuthenticationService,
              private transactionService: TransactionService,
              private ngxLoaderService: NgxUiLoaderService) { }

  ngOnInit(): void {
    if (this.authenticationService.getCurrentUser()) {
      this.showCustomerForm = true;
    }
  }

  customerUpdated($event) {
    if ($event) {
      this.userIdentified = true;
      this.sharedVariablesService.transaction.customerId = $event.id;
      this.ngxLoaderService.start();
      this.transactionService.createOrUpdateTransaction(this.sharedVariablesService.transaction).toPromise().then((transaction) => {
        this.sharedVariablesService.transaction = transaction;
        this.ngxLoaderService.stop();
        this.router.navigate(['/recapitulatif']);
      });
    }
  }

  /**
   * Go to the top of the page.
   */
  goUp() {
    window.scroll(0, 0);
  }
}
