import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Advertisement } from '../../lib/model/Advertisement';
import { Organisation } from '../../lib/model/Organisation';
import { Transaction } from '../../lib/model/Transaction';
import { AdvertisementService } from '../../lib/services/advertisement.service';
import { OrganisationService } from '../../lib/services/organisation.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  public advertisement: Advertisement;
  public banner: Advertisement;
  public transactionCopy: Transaction;
  public organisation: Organisation;
  public limit: Date;

  constructor(private route: ActivatedRoute,
              private ngxLoaderService: NgxUiLoaderService,
              private transactionService: TransactionService,
              private organisationService: OrganisationService,
              private advertisementService: AdvertisementService,
              public sharedVariablesService: SharedVariablesService) { }

  ngOnInit() {
    const transactionId = this.route.snapshot.paramMap.get('id');

    this.ngxLoaderService.start();

    this.transactionService.getTransaction(transactionId).toPromise().then((transaction) => {
      this.limit = transaction.validationDate;
      this.limit.setDate(this.limit.getDate() + 1);

      if (this.sharedVariablesService.transaction && this.sharedVariablesService.transaction.id === transactionId) {
        this.transactionCopy = transaction;

        this.organisationService.getOrganisation(transaction.organisationId).toPromise().then((organisation) => {
          this.organisation = organisation;
          this.sharedVariablesService.clear();
        });
      } else {
        this.transactionCopy = transaction;
      }
      this.ngxLoaderService.stop();
    });

    // We look for CTA banner
    this.advertisementService.getAdvertisements('B2CWEB', 'ORDER_CONFIRMATION_CTA').toPromise().then((advertisements) => {
      if (advertisements && advertisements.length > 0) {
        this.banner = advertisements[0];
      }
    });
  }
}
