import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {ArticlePage, ArticlePageAdapter} from '../model/ArticlePage';

@Injectable({
  providedIn: 'root'
})
export class ArticleService extends ApiService {

  constructor(private http: HttpClient,
              private articlePageAdapter: ArticlePageAdapter) {
    super();
  }

  /**
   * Return all articles for selected product
   */
  findAllArticles(productId: string, setExpiryDateFrom: boolean = true): Observable<ArticlePage> {
    let params = new HttpParams();

    const dlcMin = (new Date());
    dlcMin.setDate(dlcMin.getDate() + 2);

    params = params.set('productIds', productId)
                   .set('locationStatus', 'OCCUPIED')
                   .set('onlyArticlesInLocations', 'true');

    if (setExpiryDateFrom) {
      params = params.set('expiryDateFrom', dlcMin.toISOString().substr(0, 10));
    }

    return this.http.get<ArticlePage>(this.baseUri + 'articles/', {params}).pipe(map(item => this.articlePageAdapter.adapt(item)));
  }
}
