import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PricePipe } from './helpers/price.pipe';
import { AdvertisementService } from './services/advertisement.service';
import { AuthenticationService } from './services/authentication.service';
import { CategoryService } from './services/category.service';
import { CustomerService } from './services/customer.service';
import { OrganisationService } from './services/organisation.service';
import { ProductService } from './services/product.service';
import { TransactionService } from './services/transaction.service';
import {LocationNumberPipe} from './helpers/locationNumber.pipe';



@NgModule({
  declarations: [
    PricePipe,
    LocationNumberPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    HttpClientModule,
    PricePipe,
    LocationNumberPipe,
  ],
  providers: [
    OrganisationService,
    CategoryService,
    AdvertisementService,
    TransactionService,
    AuthenticationService,
    CustomerService,
    ProductService,
  ]
})
export class LibModule { }
