import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { User, UserAdapter } from '../model/User';
import { Customer, CustomerAdapter } from '../model/Customer';
import { ApiService } from './api.service';
import { CustomerService } from './customer.service';
import { SharedVariablesService } from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService extends ApiService {
  public currentUser: Observable<User | Customer>;
  public currentUserSubject: BehaviorSubject<User | Customer>;

  constructor(private http: HttpClient,
              private router: Router,
              private userAdapter: UserAdapter,
              private customerAdapter: CustomerAdapter,
              private customerService: CustomerService,
              private sharedVariablesService: SharedVariablesService) {
    super();

    if (window.sessionStorage.getItem('customer')) {
      this.currentUserSubject = new BehaviorSubject<Customer>(this.customerAdapter.adapt(JSON.parse(window.sessionStorage.getItem('customer'))));
    } else {
      this.currentUserSubject = new BehaviorSubject<User | Customer>(undefined);
    }

    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * Login method.
   * @param userEmail
   * @param userPassword
   * @param type
   */
  login(userEmail: string, userPassword: string, type: string = 'USER'): Observable<User> {
    const loginParams = {email: userEmail, password: userPassword, instanceId: this.sharedVariablesService.instance, type};

    return this.http.post<User>(this.baseUriNoAuthKey + 'identity/authenticate', loginParams).pipe(
      map((item: any) => {
        const tmpUser = this.userAdapter.adapt(item);
        this.currentUserSubject.next(tmpUser);
        this.saveUser();
        return tmpUser;
      })
    );
  }

  /**
   * Login method for customer.
   * @param userEmail
   * @param userPassword
   * @param type
   */
  customerLogin(userEmail: string, userPassword: string, type: string = 'CUSTOMER'): Observable<Customer> {
    const loginParams = {email: userEmail, password: userPassword, instanceId: this.sharedVariablesService.instance, type};

    return this.http.post<Customer>(this.baseUriNoAuthKey + 'identity/authenticate', loginParams).pipe(
      switchMap((item: any) => {
        return this.customerService.getCustomer(item.id).pipe(map((customer: Customer) => {
          customer.token = item.token;
          this.currentUserSubject.next(customer);
          this.saveUser();
          return customer;
        }))
      })
    );
  }

  /**
   * Save current user in localstorage.
   */
  saveUser() {
    window.sessionStorage.setItem('customer', JSON.stringify(this.getCurrentUser()));
  }

  /**
   * Retrieve user from token.
   * @param token
   */
  getUserFromToken(token?: string): Observable<User> {
    let headers = new HttpHeaders();
    if (token !== undefined) {
      headers = headers.set('Authorization', 'Bearer ' + token);
    }
    return this.http.get<User>(this.baseUriNoAuthKey + 'identity/me', {headers})
      .pipe(map((item: any) => this.userAdapter.adapt(item)));
  }

  /**
   * Reset password.
   * @param userEmail
   * @param token
   * @param password
   */
  resetPassword(userEmail: string, token: string, password: string) {
    return this.http.post(this.baseUri + 'customers/' + userEmail + '/password/' + token, {password});
  }

  /**
   * Logout method.
   */
  logout(redirectToLogin: boolean = true): void {
    this.currentUser = undefined;
    this.currentUserSubject.next(undefined);
    window.sessionStorage.removeItem('customer');
    if (redirectToLogin) {
      this.router.navigate(['/']);
    }
  }

  getCurrentUser(): User | Customer {
    return this.currentUserSubject.getValue();
  }
}
