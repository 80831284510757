<div class="section container">
  <h1 class="text-center">Définir un nouveau mot de passe</h1>
  <div class="summary col-xs-12 col-md-6 col-md-offset-3">
    <p>Veuillez définir un nouveau mot de passe pour votre compte Fraich' Connection (minimum 8 caractères).</p>
    <form [formGroup]="resetPasswordForm" (ngSubmit)="submit()" (change)="checkPasswordsMatch()" autocomplete="off">
      <mat-form-field class="full-width">
        <mat-label>Mot de passe *</mat-label>
        <input matInput type="password" formControlName="password" autocomplete="new-password"/>
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Confirmation du mot de passe *</mat-label>
        <input matInput type="password" formControlName="passwordConfirmation" (keyup)="checkPasswordsMatch()" autocomplete="new-password"/>
      </mat-form-field>
      <div class="messages mb10" *ngIf="passwordMatchError || passwordMatchSuccess">
        <p class="vertical-align success" *ngIf="passwordMatchSuccess"><mat-icon class="mr5">check</mat-icon> Les mots de passe sont identiques.</p>
        <p class="vertical-align error" *ngIf="passwordMatchError"><mat-icon class="mr5">close</mat-icon> Les mots de passe ne correspondent pas.</p>
      </div>
      <div class="text-center">
        <button mat-flat-button color="primary" type="submit" [disabled]="!resetPasswordForm.valid || !passwordMatchSuccess">Valider</button>
      </div>
    </form>
  </div>
</div>
