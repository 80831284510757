<div class="section container">
  <h1>Finalisez votre commande <img class="poulette" src="/assets/images/poulette.svg" alt=""></h1>
  <div class="row authentication-options" *ngIf="!showNoAccount">
    <div class="col-xs-12 col-sm-6 col-md-6" [ngClass]="{ 'col-md-offset-3': userIdentified }">
      <div class="summary">
        <h2 class="text-center" *ngIf="!userIdentified">Déjà client ?</h2>
        <app-login-form (customerAuthentified)="customerUpdated($event)"></app-login-form>
      </div>
    </div>
    <div class="col-xs-12 col-sm-6 col-md-6" *ngIf="!userIdentified">
      <div class="summary text-center not-client" [ngClass]="{ 'mt0': showCustomerForm }">
        <div *ngIf="!sharedVariablesService.transaction?.customer?.id">
          <h2>Pas encore client ?</h2>
          <p>Pour gagner du temps lors de vos prochaines courses, nous proposons de conserver vos coordonnées.</p>
        </div>
        <div *ngIf="sharedVariablesService.transaction?.customer?.id">
          <h2>Vos informations client</h2>
          <p>Merci de vérifier vos coordonnées.</p>
        </div>
        <button mat-flat-button color="primary" class="mt10" *ngIf="!showCustomerForm" (click)="showCustomerForm = !showCustomerForm">Créer un compte</button>
        <div class="text-left" *ngIf="showCustomerForm">
          <app-customer-form (customerUpdated)="customerUpdated($event)"></app-customer-form>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center mt20 mb40" *ngIf="!showNoAccount && !userIdentified">
    <div class="primary link" (click)="showNoAccount = true; goUp();">Continuer sans créer de compte (votre email reste obligatoire pour le retrait de votre commande)</div>
  </div>

  <div class="row" *ngIf="showNoAccount">
      <div class="col-xs-12 col-sm-6 col-md-6 col-md-offset-3">
        <div class="summary">
          <h2 class="text-center">Continuer sans compte</h2>
          <app-no-account (customerUpdated)="customerUpdated($event)"></app-no-account>
        </div>
      </div>
  </div>

  <div class="text-center mt20 mb40" *ngIf="showNoAccount">
    <div class="primary link" (click)="showNoAccount = false; goUp()">Se connecter / Créer un compte</div>
  </div>
</div>
