import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Customer } from '../../lib/model/Customer';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public searchForm: FormGroup;
  public animateBadge: boolean = false;
  public showCartPanel: boolean = false;
  public showMenu: boolean = false;
  public showCartInPage: boolean = false;
  public showLogin: boolean = false;
  private transactionSubscription: Subscription;
  private itemsInTransaction: number = 0;
  private queryParamsSubscription: Subscription;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.sharedVariablesService.showCartInPage = this.sharedVariablesService.config.SHOW_CART_IN_PAGE && window.innerWidth >= 1361;
  }

  constructor(private router: Router,
              private route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              public authenticationService: AuthenticationService,
              private snackBar: MatSnackBar) {
    this.showCartInPage = this.sharedVariablesService.config.SHOW_CART_IN_PAGE;
    this.getScreenSize(undefined);
  }

  ngOnInit() {
    this.transactionSubscription = this.sharedVariablesService.updatedTransaction.subscribe((transaction) => {
      if (transaction.items.length !== this.itemsInTransaction) {
        this.animateBadge = true;
        window.setTimeout(() => {
          this.itemsInTransaction = transaction.items.length;
          this.animateBadge = false;
        }, 2000);

        if (this.sharedVariablesService.showCartInPage && this.sharedVariablesService.config.SHOW_CART_IN_PAGE) {
          this.showCartPanel = true;
          this.sharedVariablesService.cartInPageVisible = true;
        }
      }
    });

    // Initialize search form
    this.searchForm = new FormGroup({
      search: new FormControl(undefined),
    });

    // Subscribe to changes for term GET parameter (to display login form)
    this.queryParamsSubscription = this.route.queryParamMap.subscribe((params) => {
      if (!this.showLogin && params.get('showLogin')) {
        this.toggleLogin();
      }
    });
  }

  ngOnDestroy(): void {
    this.transactionSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
  }

  search() {
    const term = this.searchForm.get('search').value;
    this.router.navigate(['recherche'], { queryParams: { term }});
  }

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  closeMenu() {
    if (this.showMenu) {
      this.showMenu = false;
    }
  }

  toggleCart() {
    this.showLogin = false;
    this.showCartPanel = !this.showCartPanel;
    if (this.sharedVariablesService.showCartInPage) {
      this.sharedVariablesService.cartInPageVisible = this.showCartPanel;
    } else {
      this.sharedVariablesService.cartInPageVisible = false;
    }
  }

  closeCartPanel() {
    if (this.showCartPanel && !this.showCartInPage) {
      this.showCartPanel = false;
    }
  }

  toggleLogin() {
    this.showLogin = !this.showLogin;
    if (this.showLogin) {
      this.showCartPanel = false;
    }
  }

  customerLoggedIn(customer: Customer) {
    this.toggleLogin();
    this.router.navigate(['mon-compte']);
  }
}
