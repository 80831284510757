<div class="products">
  <div class="tree mt100">
    <app-category-tree [category]="category"></app-category-tree>
  </div>
  <div class="section container items">
    <div class="carousel container" *ngIf="carouselAdvertisementsImages.length > 0">
      <slideshow [minHeight]="'178px'"
                 [autoPlay]="true"
                 [showArrows]="false"
                 [showDots]="false"
                 [autoPlayInterval]="6000"
                 [backgroundSize]="'cover'"
                 [imageUrls]="carouselAdvertisementsImages"
                 [lazyLoad]="carouselAdvertisementsImages?.length > 1"
                 [autoPlayWaitForLazyLoad]="true">
      </slideshow>
    </div>
    <div *ngIf="searchTerm">
      <h2>Résultats de recherche pour &laquo; {{searchTerm}} &raquo;</h2>
      <p *ngIf="products?.length === 0">Aucun produit trouvé.</p>
    </div>
    <div *ngIf="category">
      <button class="back-button" mat-mini-fab color="accent" (click)="goBack()"><mat-icon>arrow_back</mat-icon></button>
      <h2>{{ category.name }}</h2>
      <div *ngIf="category.children && category.children.length > 0" class="row">
        <div class="col-xs-6 col-sm-6 col-md-3" *ngFor="let subcategory of category.children">
          <div class="category" [routerLink]="['/produits/', subcategory.code]">
            <img [src]="subcategory.pictureUri" alt="">
            <div class="title">{{ subcategory.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="no-product" *ngIf="!category?.children && products.length === 0">
      Aucun produit disponible actuellement.
    </div>
    <div *ngIf="products" class="row">
      <div class="col-sm-6 col-md-3" *ngFor="let product of products">
        <div class="product" (click)="openSelectLocationModal(product)">
          <img [src]="product.getDefaultImage().url" [alt]="product.name">
          <div class="title">{{ product.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="cart" [ngClass]="{ 'cart-visible': sharedVariablesService.cartInPageVisible }">
  </div>
</div>


