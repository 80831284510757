import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { Brand } from './Brand';
import { Price, PriceAdapter } from './Price';
import { ProductDeclination, ProductDeclinationAdapter, ProductDeclinationImage } from './ProductDeclination';
import { ProductImage, ProductImageAdapter, ProductImageType } from './ProductImage';
import { ProductOption } from './ProductOption';
import { RelatedProduct, RelatedProductAdapter } from './RelatedProduct';

const DEFAULT_IMAGE_URL = 'https://app.keyneosoft.io/assets/img/no-image.png';

export class Product {
  id: string;
  name: string;
  description?: string;
  shortDescription?: string;
  reference: string;
  onlineDate?: Date;
  rangeCodes?: string[];
  mainCategoryCode: string;
  categoryCodes?: string[];
  vatCode: string;
  active: boolean = true;
  organisationId?: string = 'ROOT';

  // Set EAN/SKU in product only no variants are defined
  ean?: string;
  sku?: string;
  rank?: number;
  type?: ProductType = ProductType.ITEM;

  // Only necessary for PREPAID_CARD products
  prepaidCardAmount?: number;

  eans?: string[] = [];

  images: ProductImage[] = [];
  productProfileId: string;
  properties: { [name: string]: string[] };
  tags: string[] = [];
  icons: string[] = [];

  relatedProducts: RelatedProduct[] = [];

  upSellingProducts?: RelatedProduct[] = [];

  saleChannelCodes?: string[];
  brand?: Brand;
  salesUnitCount?: number;
  salesUnitLabel?: string;
  unitCapacityLabel?: string;
  quantityCoefficient: number = 1;

  minPrice?: number;
  maxPrice?: number;
  stock?: number;
  creationDate?: Date;
  modificationDate?: Date;

  // Only for search engines. If product contains variants we take the lowest price.
  price?: Price;
  quota?: number;
  availableCount?: number;
  productDeclinations?: ProductDeclination[];

  normalPrice: number;
  defaultImage: ProductImage|ProductDeclinationImage;
  options: ProductOption[] = [];
  depositAmount: number = 0;

  excludedOrganisationIds: string[] = [];
  packagingLabel: string;
  supplierPCB: number;
  deee: number;
  ecopart: number;


  constructor(id?: string, name?: string, description?: string, shortDescription?: string, reference?: string, onlineDate?: Date, rangeCodes?: string[],
              mainCategoryCode?: string, categoryCodes?: string[], vatCode?: string, active?: boolean, organisationId?: string, ean?: string,
              sku?: string, rank?: number, type?: ProductType, prepaidCardAmount?: number, eans?: string[], images?: ProductImage[],
              productProfileId?: string, properties?: { [p: string]: string[] }, tags?: string[], icons?: string[], relatedProducts?: RelatedProduct[],
              upSellingProducts?: RelatedProduct[], saleChannelCodes?: string[], brand?: Brand, salesUnitCount?: number, salesUnitLabel?: string,
              unitCapacityLabel?: string, quantityCoefficient?: number, minPrice?: number, maxPrice?: number, stock?: number, creationDate?: Date,
              modificationDate?: Date, price?: Price, quota?: number, availableCount?: number, productDeclinations?: ProductDeclination[], normalPrice?: number,
              options?: ProductOption[], depositAmount?: number, excludedOrganisationIds?: string[], packagingLabel?: string, supplierPCB?: number,
              deee?: number, ecopart?: number) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.shortDescription = shortDescription;
    this.reference = reference;
    this.onlineDate = onlineDate;
    this.rangeCodes = rangeCodes;
    this.mainCategoryCode = mainCategoryCode;
    this.categoryCodes = categoryCodes;
    this.vatCode = vatCode;
    this.active = active;
    this.organisationId = organisationId;
    this.ean = ean;
    this.sku = sku;
    this.rank = rank;
    this.type = type;
    this.prepaidCardAmount = prepaidCardAmount;
    this.eans = eans;
    this.images = images;
    this.productProfileId = productProfileId;
    this.properties = properties;
    this.tags = tags ? tags : [];
    this.icons = icons;
    this.relatedProducts = relatedProducts;
    this.upSellingProducts = upSellingProducts;
    this.saleChannelCodes = saleChannelCodes;
    this.brand = brand;
    this.salesUnitCount = salesUnitCount;
    this.salesUnitLabel = salesUnitLabel;
    this.unitCapacityLabel = unitCapacityLabel;
    this.quantityCoefficient = quantityCoefficient ? quantityCoefficient : 1;
    this.minPrice = minPrice;
    this.maxPrice = maxPrice;
    this.stock = stock;
    this.creationDate = creationDate;
    this.modificationDate = modificationDate;
    this.price = price;
    this.quota = quota;
    this.availableCount = availableCount;
    this.productDeclinations = productDeclinations;
    this.normalPrice = normalPrice;
    this.options = options;
    this.depositAmount = depositAmount;
    this.defaultImage = this.getDefaultImage();
    this.excludedOrganisationIds = excludedOrganisationIds;
    this.packagingLabel = packagingLabel;
    this.supplierPCB = supplierPCB;
    this.deee = deee;
    this.ecopart = ecopart;
  }

  getDefaultPicto() {
    for (const image of this.images) {
      if (image.type === ProductImageType.PICTO_DEFAULT) {
        return image;
      }
    }
  }

  getDefaultImage(): ProductImage|ProductDeclinationImage {
    let haveDefaultImage: number = 0;
    let defaultImg: any;

    if (this.images !== undefined && this.images.length > 0) {

      for (const image of this.images) {
        if (image.type === ProductImageType.IMAGE_DEFAULT) {
          haveDefaultImage += 1;
          defaultImg = image;
        }

        if (haveDefaultImage === 0) {
          defaultImg = this.images[0];
        }
      }
      return defaultImg;

      // Create defaultImage for product with declination
    } else if (this.productDeclinations && this.productDeclinations.length > 0) {
      if (this.productDeclinations[0].images && this.productDeclinations[0].images.length > 0) {
        defaultImg = this.productDeclinations[0].images[0];
      }
      return defaultImg;
    } else {
      return new ProductImage('', ProductImageType.IMAGE,  'generatedDefault', DEFAULT_IMAGE_URL, 0);
    }
  }
}

@Injectable({
  providedIn: 'root',
})
export class ProductAdapter implements Adapter<Product> {

  adapt(item: any): Product {
    const productImageAdapter: ProductImageAdapter = new ProductImageAdapter();
    const relatedProductAdapter: RelatedProductAdapter = new RelatedProductAdapter();
    const productDeclinationAdapter: ProductDeclinationAdapter = new ProductDeclinationAdapter();
    const priceAdapter: PriceAdapter = new PriceAdapter();

    return new Product(
      item.id,
      item.name,
      item.description,
      item.shortDescription,
      item.reference,
      item.onlineDate ? new Date(item.onlineDate.toString()) : undefined,
      item.rangeCodes,
      item.mainCategoryCode,
      item.categoryCodes,
      item.vatCode,
      item.active,
      item.organisationId,
      item.ean,
      item.sku,
      item.rank,
      item.type,
      item.prepaidCardAmount,
      item.eans ? item.eans : [],
      item.images ? item.images.map((e) => {
        e = productImageAdapter.adapt(e);
        return e;
      }) : undefined,
      item.productProfileId,
      item.properties ? item.properties : {},
      item.tags ? item.tags : [],
      item.icons,
      item.relatedProducts ? item.relatedProducts.map((e) => {
        e = relatedProductAdapter.adapt(e);
        return e;
      }) : undefined,
      item.upSellingProducts ? item.upSellingProducts.map((e) => {
        e = relatedProductAdapter.adapt(e);
        return e;
      }) : undefined,
      item.saleChannelCodes,
      item.brand,
      item.salesUnitCount,
      item.salesUnitLabel,
      item.unitCapacityLabel,
      item.quantityCoefficient,
      item.minPrice,
      item.maxPrice,
      item.stock,
      item.creationDate ? new Date(item.creationDate.toString()) : undefined,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.price ? priceAdapter.adapt(item.price) : undefined,
      item.quota,
      item.availableCount,
      item.productDeclinations ? item.productDeclinations.map((e) => {
        e = productDeclinationAdapter.adapt(e);
        return e;
      }) : undefined,
      item.normalPrice,
      item.options ? item.options : undefined,
      item.depositAmount,
      item.excludedOrganisationIds ? item.excludedOrganisationIds : [],
      item.packagingLabel,
      item.supplierPCB,
      item.deee,
      item.ecopart
    );
  }
}

export enum ProductType {
  ITEM = 'ITEM',
  PREPAID_CARD = 'PREPAID_CARD',
  SERVICE = 'SERVICE',
}
