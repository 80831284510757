import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { ProductVariantAdapter } from './ProductVariant';
import { StorageLocation, StorageLocationAdapter } from './StorageLocation';

export interface Promotion {
  promotionName: string;
  discountType: string;
  packInstancesApplied?: string[];
}

export class Item {
  ean: string;
  locationId: string;
  name: string;
  description: string;
  quantity: number;
  salesUnitLabel: string;
  quantityCoefficient: number;
  articleId: string;
  productType: string;
  unitPrice: number;
  unitOptionFreePrice: number;
  unitGrossPrice: number;
  unitDiscountedPrice: number;
  vatCode: string;
  vatAmount: number;
  discountRate: number;
  unitDiscountAmount: number;
  totalDiscountAmount: number;
  unitDiscountFreeVatAmount: number;
  discountFreeVatAmount: number;
  totalGrossAmount: number;
  totalAmount: number;
  type: string;
  marketingActions: [];
  appliedPromotions: Promotion[];
  productId: string;
  unitDiscountAndPromotionFreeGrossPrice: number;
  totalDiscountFreeAmount: number;
  totalDiscountFreeGrossAmount: number;
  unitVentilatedPrice: number;
  totalVentilatedPrice: number;
  totalGrossVentilatedPrice: number;
  vatRate: number;
  defaultImage: string;
  rank: number;
  location: StorageLocation;
  price: number;
  quantityWithCoefficient: number;

  constructor(ean: string,
              locationId: string,
              name: string,
              description: string,
              quantity: number,
              salesUnitLabel: string,
              quantityCoefficient: number,
              articleId: string,
              productType: string,
              unitPrice: number,
              unitOptionFreePrice: number,
              unitGrossPrice: number,
              unitDiscountedPrice: number,
              vatCode: string,
              vatAmount: number,
              discountRate: number,
              unitDiscountAmount: number,
              totalDiscountAmount: number,
              unitDiscountFreeVatAmount: number,
              discountFreeVatAmount: number,
              totalGrossAmount: number,
              totalAmount: number,
              type: string,
              marketingActions: [],
              appliedPromotions: Promotion[],
              productId: string,
              unitDiscountAndPromotionFreeGrossPrice: number,
              totalDiscountFreeAmount: number,
              totalDiscountFreeGrossAmount: number,
              unitVentilatedPrice: number,
              totalVentilatedPrice: number,
              totalGrossVentilatedPrice: number,
              vatRate: number,
              defaultImage: string,
              rank: number,
              location: StorageLocation,
              price: number,
              quantityWithCoefficient: number) {
    this.ean = ean;
    this.locationId = locationId;
    this.name = name;
    this.description = description;
    this.quantity = quantity;
    this.salesUnitLabel = salesUnitLabel;
    this.quantityCoefficient = quantityCoefficient;
    this.articleId = articleId;
    this.productType = productType;
    this.unitPrice = unitPrice;
    this.unitOptionFreePrice = unitOptionFreePrice;
    this.unitGrossPrice = unitGrossPrice;
    this.unitDiscountedPrice = unitDiscountedPrice;
    this.vatCode = vatCode;
    this.vatAmount = vatAmount;
    this.discountRate = discountRate;
    this.unitDiscountAmount = unitDiscountAmount;
    this.totalDiscountAmount = totalDiscountAmount;
    this.unitDiscountFreeVatAmount = unitDiscountFreeVatAmount;
    this.discountFreeVatAmount = discountFreeVatAmount;
    this.totalGrossAmount = totalGrossAmount;
    this.totalAmount = totalAmount;
    this.type = type;
    this.marketingActions = marketingActions;
    this.appliedPromotions = appliedPromotions;
    this.productId = productId;
    this.unitDiscountAndPromotionFreeGrossPrice = unitDiscountAndPromotionFreeGrossPrice;
    this.totalDiscountFreeAmount = totalDiscountFreeAmount;
    this.totalDiscountFreeGrossAmount = totalDiscountFreeGrossAmount;
    this.unitVentilatedPrice = unitVentilatedPrice;
    this.totalVentilatedPrice = totalVentilatedPrice;
    this.totalGrossVentilatedPrice = totalGrossVentilatedPrice;
    this.vatRate = vatRate;
    this.defaultImage = defaultImage;
    this.rank = rank;
    this.location = location;
    this.price = price;
    this.quantityWithCoefficient = quantityWithCoefficient;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ItemAdapter implements Adapter<Item> {
  adapt(item: any): Item {
    const storageLocationAdapter = new StorageLocationAdapter();

    return new Item(
      item.ean,
      item.locationId,
      item.name,
      item.description,
      item.quantity,
      item.salesUnitLabel,
      item.quantityCoefficient,
      item.articleId,
      item.productType,
      item.unitPrice,
      item.unitOptionFreePrice,
      item.unitGrossPrice,
      item.unitDiscountedPrice,
      item.vatCode,
      item.vatAmount,
      item.discountRate,
      item.unitDiscountAmount,
      item.totalDiscountAmount,
      item.unitDiscountFreeVatAmount,
      item.discountFreeVatAmount,
      item.totalGrossAmount,
      item.totalAmount,
      item.type,
      item.marketingActions,
      item.appliedPromotions,
      item.productId,
      item.unitDiscountAndPromotionFreeGrossPrice,
      item.totalDiscountFreeAmount,
      item.totalDiscountFreeGrossAmount,
      item.unitVentilatedPrice,
      item.totalVentilatedPrice,
      item.totalGrossVentilatedPrice,
      item.vatRate,
      item.defaultImage,
      item.rank,
      item.location ? storageLocationAdapter.adapt(item.location) : undefined,
      item.price,
      item.quantityWithCoefficient
    );
  }

  prepare(object: Item): any {
    const target = {};

    Object.assign(target, object);
    delete target['rank'];

    return target;
  }
}
