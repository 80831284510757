export class PaymentRequestUrls {
  cancelUrl: string;
  errorUrl: string;
  returnUrl: string;
  refusedUrl: string;
  successUrl: string;

  constructor(cancelUrl: string, errorUrl: string, returnUrl: string, refusedUrl: string, successUrl: string) {
    this.cancelUrl = cancelUrl;
    this.errorUrl = errorUrl;
    this.returnUrl = returnUrl;
    this.refusedUrl = refusedUrl;
    this.successUrl = successUrl;
  }
}
