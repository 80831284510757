import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Address, AddressType } from '../../lib/model/Address';
import { Customer } from '../../lib/model/Customer';
import { User } from '../../lib/model/User';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { CustomerService } from '../../lib/services/customer.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  @Output() customerAuthentified: EventEmitter<Customer|User> = new EventEmitter<Customer|User>();
  @Input() inMenu: boolean = false;
  public loginForm: FormGroup;

  constructor(public sharedVariablesService: SharedVariablesService,
              private authenticationService: AuthenticationService,
              public dialog: MatDialog,
              private ngxLoaderService: NgxUiLoaderService) {
    this.loginForm = new FormGroup({
      email: new FormControl(this.authenticationService.getCurrentUser() ? this.authenticationService.getCurrentUser().email : undefined, [Validators.required, Validators.email]),
      password: new FormControl(undefined, Validators.required),
    });
  }

  ngOnInit() {
  }

  submitLoginForm() {
    if (this.loginForm.valid) {
      const email = this.loginForm.get('email').value;
      const password = this.loginForm.get('password').value;

      this.ngxLoaderService.start();
      this.authenticationService.customerLogin(email, password).toPromise().then((customer) => {
        this.customerAuthentified.emit(<Customer>customer);
        this.ngxLoaderService.stop();
      });
    }
  }

  openResetPasswordDialog(): void {
    const dialogRef = this.dialog.open(ResetPasswordComponent, { width: '500px', data: { email: this.loginForm.get('email').value }});
  }
}
