<div class="section success-header">
  <div class="background">
    <div class="radis">
      <img src="/assets/images/account.svg"/>
    </div>
    <div class="mt10 medium">VOTRE COMMANDE EST VALIDÉE</div>
  </div>
</div>
<div class="container">
  <div class="text-center">
    <h1>Merci !</h1>
    <p class="mb50">Votre commande et votre paiement ont bien été pris en compte. <br>Vous recevrez d’ici quelques minutes un email récapitulatif.</p>

    <div class="row" *ngIf="transactionCopy">
      <div class="col-xs-12 col-md-4">
        <div class="summary">
          <h2 class="primary">Nos villages sont ouverts 7J/ 24H/24</h2>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="summary">
          <h2 class="primary mt0">À retirer avant le :</h2>
          <div class="medium">{{ limit | date: "short"}}</div>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="summary">
          <h2 class="primary mt0">Code de retrait
            <br><span class="special">(À renseigner sur la borne une fois sur place)</span></h2>
            <div class="medium">{{ transactionCopy.delivery?.orderId }}</div>
        </div>
      </div>
    </div>

    <p class="primary">En cas d’empêchement et passé le délai de 24h, les produits sont remis en vente et votre commande est annulée et non remboursée.</p>

    <div class="banner" *ngIf="banner">
      <a [href]="banner.redirectionUrl"><img [src]="banner.mediaUrl" [alt]="banner.name"></a>
    </div>

    <div class="mt30 mb30"><button mat-flat-button color="primary" [routerLink]="['']">Retour à la page d'acceuil</button></div>
  </div>
</div>
