import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Category } from '../model/Category';
import { Config } from '../model/Config';
import { Customer, CustomerAdapter } from '../model/Customer';
import { Organisation, OrganisationAdapter } from '../model/Organisation';
import { SaleChannel, SaleChannelAdapter } from '../model/SaleChannel';
import { Transaction, TransactionAdapter } from '../model/Transaction';
import { User, UserAdapter } from '../model/User';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SharedVariablesService extends ApiService {
  private _transaction: Transaction;
  private _saleChannel: SaleChannel;
  private _organisation: Organisation;
  private _user: Customer|User;
  private readonly _instance: string = 'fraichconnection';
  public saleChannelChanged: Subject<SaleChannel> = new Subject<SaleChannel>();
  public rootCategory: Category;
  public updatedTransaction: EventEmitter<Transaction> = new EventEmitter<Transaction>();
  public config: Config = new Config();
  public showCartInPage: boolean = false;
  public cartInPageVisible: boolean = false;

  constructor(private transactionAdapter: TransactionAdapter,
              private saleChannelAdapter: SaleChannelAdapter,
              private organisationAdapter: OrganisationAdapter,
              private customerAdapter: CustomerAdapter,
              private userAdapter: UserAdapter) {
    super();
    // Load from local storage
    if (window.localStorage.getItem('organisation') !== null) {
      this.organisation = this.organisationAdapter.adapt(JSON.parse(window.localStorage.getItem('organisation')));
    }
    if (window.localStorage.getItem('saleChannel') !== null) {
      this.saleChannel = this.saleChannelAdapter.adapt(JSON.parse(window.localStorage.getItem('saleChannel')));
    }
    // Load from session
    if (window.sessionStorage.getItem('transaction') !== null) {
      this.transaction = this.transactionAdapter.adapt(JSON.parse(window.sessionStorage.getItem('transaction')));
    }
    if (window.sessionStorage.getItem('user') !== null) {
      this.user = this.userAdapter.adapt(JSON.parse(window.sessionStorage.getItem('user')));
    }

    this._instance = this.config['INSTANCE_ID'];
  }

  clear() {
    this._transaction = undefined;
    window.sessionStorage.removeItem('transaction');
  }

  get transaction(): Transaction {
    return this._transaction;
  }

  set transaction(value: Transaction) {
    this._transaction = value;
    if (value !== undefined) {
      window.sessionStorage.setItem('transaction', JSON.stringify(this._transaction.prepareForJSON()));
    } else {
      window.sessionStorage.removeItem('transaction');
    }
    this.updatedTransaction.emit(value);
  }

  get organisation(): Organisation {
    return this._organisation;
  }

  set organisation(value: Organisation) {
    this._organisation = value;
    if (value !== undefined) {
      window.localStorage.setItem('organisation', JSON.stringify(this.organisationAdapter.prepare(this.organisation)));
    } else {
      window.localStorage.removeItem('organisation');
    }
  }

  get user(): User {
    return this._user as User;
  }

  set user(value: User) {
    this._user = value;
    if (value !== undefined) {
      window.sessionStorage.setItem('user', JSON.stringify(this.user));
    } else {
      window.sessionStorage.removeItem('user');
    }
  }

  get saleChannel(): SaleChannel {
    return this._saleChannel;
  }

  set saleChannel(value: SaleChannel) {
    this._saleChannel = value;
    if (value !== undefined) {
      window.localStorage.setItem('saleChannel', JSON.stringify(this.saleChannelAdapter.prepare(this._saleChannel)));
      this.saleChannelChanged.next(this.saleChannel);
    } else {
      this.rootCategory = undefined;
      window.localStorage.removeItem('saleChannel');
    }
  }

  get instance(): string {
    return this._instance;
  }
}
