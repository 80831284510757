import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerService } from '../../lib/services/customer.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  newsletterForm: FormGroup;
  public emailError: boolean = false;

  constructor(private customerService: CustomerService,
              private snackbar: MatSnackBar) {
    this.newsletterForm = new FormGroup({
      email: new FormControl(undefined, [Validators.required, Validators.pattern(/^((?!\.)[\w-_.]*[\w])(@[\w-]+)(\.\w+(\.\w+)?[^.\W])$/)])
    });
  }

  ngOnInit() {
  }

  subscribeNewsletter() {
    this.emailError = false;
    if (this.newsletterForm.valid) {
      this.customerService.subscribeNewsletter(this.newsletterForm.get('email').value).toPromise().then((result) => {
        this.newsletterForm.reset();
        this.snackbar.open('Votre inscription a bien été prise en compte !', undefined, {
          panelClass: ['success-snackbar'],
          duration: 2500,
        });
      });
    } else {
      this.emailError = true;
    }
  }
}
