import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElasticSearchResultAdapter } from '../model/ElasticSearchResult';
import { Product, ProductAdapter } from '../model/Product';
import { ProductScheme, ProductSchemeAdapter } from '../model/ProductScheme';
import { ApiService } from './api.service';
import { SharedVariablesService } from './shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends ApiService {

  constructor(private http: HttpClient,
              private productAdapter: ProductAdapter,
              private elasticAdapter: ElasticSearchResultAdapter,
              private productSchemeAdapter: ProductSchemeAdapter,
              private sharedVariablesService: SharedVariablesService) {
    super();
  }

  /**
   * Return ProductScheme for current instance.
   */
  getProductScheme(): Observable<ProductScheme> {
    return this.http.get<ProductScheme>(this.baseUri + 'model/scheme/product/' + this.sharedVariablesService.instance)
      .pipe(map(item => this.productSchemeAdapter.adapt(item)));
  }

  /**
   * Get products for current organisation.
   * @param categoryCode Code of the category to search in.
   * @param searchTerm
   */
  public getProducts(categoryCode?: string, searchTerm?: string): Observable<Product[]> {
    let params = new HttpParams();
    params = params.set('addStock', 'true');
    params = params.set('saleChannelCodes', 'B2CWEB');

    if (this.sharedVariablesService.transaction) {
      params = params.set('currentTransactionId', this.sharedVariablesService.transaction.id);

      if (this.sharedVariablesService.transaction.delivery) {
        const expectedWithdrawalDate = this.sharedVariablesService.transaction.delivery.expectedWithdrawalDate;
        params = params.set('date', expectedWithdrawalDate);
      }
    }

    if (this.sharedVariablesService.saleChannel) {
      params = params.set('saleChannelCodes', this.sharedVariablesService.saleChannel.code);
    }

    if (searchTerm) {
      params = params.set('searchValue', searchTerm);
    }

    if (categoryCode) {
      params = params.set('categories', categoryCode);
    }

    return this.http.get<Product[]>(this.baseUri + 'fraichConnection/products', {params}).pipe(
      map((data: any[]) => data.map(item => this.productAdapter.adapt(item))));
  }

  /**
   * Get a product by its ean / product id.
   * @param ean
   */
  public getProduct(ean: string): Observable<Product> {
    let params = new HttpParams();

    params = params.set('addStock', 'true');

    if (this.sharedVariablesService.transaction) {
      params = params.set('currentTransactionId', this.sharedVariablesService.transaction.id);

      if (this.sharedVariablesService.transaction.delivery) {
        const expectedWithdrawalDate = this.sharedVariablesService.transaction.delivery.expectedWithdrawalDate;
        params = params.set('date', expectedWithdrawalDate);
      }
    }

    return this.http.get<Product>(this.baseUri + 'products/' + ean, {params}).pipe(map(item => this.productAdapter.adapt(item)));
  }
}
