import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class City {
  id: string;
  country: string;
  postalCode: string;
  name: string;
  inseeCode: string;
  department: string;
  locality: string;
  location: { x: number, y: number };


  constructor(id: string,
              country: string,
              postalCode: string,
              name: string,
              inseeCode: string,
              department: string,
              locality: string,
              location: { x: number; y: number }) {
    this.id = id;
    this.country = country;
    this.postalCode = postalCode;
    this.name = name;
    this.inseeCode = inseeCode;
    this.department = department;
    this.locality = locality;
    this.location = location;
  }
}

@Injectable({
  providedIn: 'root',
})
export class CityAdapter implements Adapter<City> {

  adapt(item: any): City {
    return new City(
      item.id,
      item.country,
      item.postalCode,
      item.name,
      item.inseeCode,
      item.department,
      item.locality,
      item.location);
  }
}
