import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { PaymentRequestUrls } from '../../lib/model/PaymentRequestUrls';
import { AuthenticationService } from '../../lib/services/authentication.service';
import { PaymentService } from '../../lib/services/payment.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { TransactionService } from '../../lib/services/transaction.service';

@Component({
  selector: 'app-recap',
  templateUrl: './recap.component.html',
  styleUrls: ['./recap.component.scss']
})
export class RecapComponent implements OnInit {
  public limit: Date;
  public consent: FormGroup;
  public status: string;
  public paymentLoading: boolean = false;
  private queryParamsSubscription: Subscription;

  constructor(public sharedVariablesService: SharedVariablesService,
              public route: ActivatedRoute,
              public router: Router,
              private authenticationService: AuthenticationService,
              private transactionService: TransactionService,
              private paymentService: PaymentService,
              private ngxLoaderService: NgxUiLoaderService) {
    this.consent = new FormGroup({
      delay: new FormControl(undefined, [Validators.required]),
      generalTermsOfUse: new FormControl(undefined, [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.limit = (new Date());
    this.limit.setDate(this.limit.getDate() + 1);

    // Subscribe to params changes
    const self = this;
    this.queryParamsSubscription = this.route.queryParamMap.subscribe((params) => {
      if (params.has('status')) {
        let status = params.get('status');
        let transactionId = params.get('tid');

        if (status === 'success') {
          // Redirect to special page
          this.router.navigate(['confirmation-de-commande', transactionId]);
        } else { // cancelled, refused, error
          this.status = status;
        }
      }
    });
  }

  processPayment() {
    if (this.sharedVariablesService.transaction.customer && this.sharedVariablesService.transaction.items.length > 0) {
      this.paymentLoading = true;

      const paymentRequestUrls: PaymentRequestUrls = new PaymentRequestUrls(
        window.location.href.split('?')[0] + '?status=cancelled&tid=' + this.sharedVariablesService.transaction.id,
        window.location.href.split('?')[0] + '?status=error&tid=' + this.sharedVariablesService.transaction.id,
        window.location.href.split('?')[0] + '?tid=' + this.sharedVariablesService.transaction.id,
        window.location.href.split('?')[0]+ '?status=refused&tid=' + this.sharedVariablesService.transaction.id,
        window.location.href.split('?')[0] + '?status=success&tid=' + this.sharedVariablesService.transaction.id,
      );

      this.paymentService.getSogecommercePaymentRequestPage(paymentRequestUrls, this.sharedVariablesService.transaction).toPromise().then((response) => {
        window.location.href = response.hostedPageUrl;
      }, (error) => {
        this.paymentLoading = false;
      });
    }
  }
}
