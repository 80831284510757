<div class="menu">
  <div *ngIf="dataSource">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="menu-tree">
      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <li class="mat-tree-node" [routerLink]="'/produits/' + node.code" (click)="closeMenu()">
          <!-- use a disabled button to provide padding for tree leaf -->
          <button mat-icon-button disabled></button>
          {{node.name}}
        </li>
      </mat-tree-node>
      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <li>
          <div class="mat-tree-node">
            <button mat-icon-button matTreeNodeToggle
                    [attr.aria-label]="'toggle ' + node.name">
              <mat-icon color="accent" class="mat-icon-rtl-mirror">{{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}</mat-icon>
            </button>
            <span [routerLink]="'/produits/' + node.code">{{node.name}}</span>
          </div>
          <ul [class.menu-tree-invisible]="!treeControl.isExpanded(node)">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </li>
      </mat-nested-tree-node>
    </mat-tree>
  </div>
</div>
