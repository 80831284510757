<form [formGroup]="userForm" (ngSubmit)="submitUserForm()">
  <div class="mt10 mb10">
    <mat-radio-group formControlName="civility" color="primary">
      <mat-radio-button value="M." class="mr10">M.</mat-radio-button>
      <mat-radio-button value="Mme">Mme</mat-radio-button>
    </mat-radio-group>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Nom *</mat-label>
      <input matInput type="text" formControlName="lastName" >
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Prénom *</mat-label>
      <input matInput type="text" formControlName="firstName">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Email *</mat-label>
      <input matInput type="text" formControlName="email" autocomplete="off" [readonly]="customer?.id">
    </mat-form-field>
  </div>
  <div>
    <div class="mb10" *ngIf="customer?.id">
      <mat-checkbox (change)="showPassword()">Modifier mon mot de passe</mat-checkbox>
    </div>
    <mat-form-field class="full-width" *ngIf="!customer?.id || editPassword">
      <mat-label>Mot de passe *</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Téléphone mobile</mat-label>
      <input matInput type="text" formControlName="phone" mask="00 00 00 00 00">
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="full-width">
      <mat-label>Date de naissance</mat-label>
      <input matInput type="text" formControlName="birthDay" [dropSpecialCharacters]="false" mask="00/00/0000">
    </mat-form-field>
  </div>
  <ng-container>
    <h3>Adresse</h3>
    <div>
      <mat-form-field class="full-width">
        <mat-label>Rue</mat-label>
        <input matInput type="text" formControlName="line1">
      </mat-form-field>
    </div>
    <div class="zip-city">
      <mat-form-field>
        <mat-label>Code postal</mat-label>
        <input matInput type="text" formControlName="postalCode" mask="00000" (keyup)="postalCodeChanged()">
      </mat-form-field>
      <mat-form-field class="city-field">
        <mat-label>Ville</mat-label>
        <input matInput type="text" formControlName="city" class="city" [matAutocomplete]="cityAutocomplete">
        <mat-autocomplete #cityAutocomplete="matAutocomplete">
          <mat-option *ngFor="let cityObj of cities" [value]="cityObj.name">
            {{ cityObj.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </ng-container>
  <div>
    <mat-checkbox formControlName="advertising" color="primary" value="true">Je souhaite recevoir des communications de la part de Fraîch' Connection</mat-checkbox>
    </div>
  <div class="mt20">
    <div class="text-center" [ngClass]="{ 'flex': customer?.id && accountPage }">
      <button mat-stroked-button type="button" *ngIf="customer?.id && accountPage" (click)="cancelEdit()">Annuler</button>
      <button *ngIf="!customer?.id" mat-flat-button color="primary" type="submit" [disabled]="!userForm.valid">Créer mon compte</button>
      <button *ngIf="customer?.id" mat-flat-button color="primary" type="submit" [disabled]="!userForm.valid">Valider</button>
    </div>
  </div>
</form>
