import { Component, Input, OnInit } from '@angular/core';
import { Order } from '../../lib/model/Order';

@Component({
  selector: 'app-customer-orders',
  templateUrl: './customer-orders.component.html',
  styleUrls: ['./customer-orders.component.scss']
})
export class CustomerOrdersComponent implements OnInit {
  @Input() orders: Order[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
