import config from '../../../assets/config.json';
import { SaleChannelProperties } from './SaleChannel';

/**
 * Configuration.
 */
export class Config {
  public INSTANCE_ID: string = 'fraichconnection';
  public ROOT_ORGANISATION_ID: string = 'fraichconnection';
  public SHOW_CART_IN_PAGE: boolean = true;
  public SHOW_MENU: boolean = true;

  constructor() {
    Object.assign(this, config);
  }

  overrideWithSaleChannelProperties(saleChannelProperties: SaleChannelProperties) {
    Object.keys(this).forEach((key) => {
      if (saleChannelProperties[key]) {
        this[key] = (saleChannelProperties[key] === 'true' ? true : ((saleChannelProperties[key] === 'false') ? false : saleChannelProperties[key]));
      }
    });
  }
}
