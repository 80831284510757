import {Location} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { IImage } from 'ng-simple-slideshow';
import {NgxUiLoaderService} from 'ngx-ui-loader';
import {Subscription} from 'rxjs';
import {Advertisement} from '../../lib/model/Advertisement';
import {Category} from '../../lib/model/Category';
import {Product} from '../../lib/model/Product';
import {AdvertisementService} from '../../lib/services/advertisement.service';
import {CategoryService} from '../../lib/services/category.service';
import {ProductService} from '../../lib/services/product.service';
import {SharedVariablesService} from '../../lib/services/shared-variables.service';
import {MatDialog} from '@angular/material/dialog';
import {SelectLockerModalComponent} from '../../partials/select-locker-modal/select-locker-modal.component';
import {ArticleService} from '../../lib/services/article.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  public carouselAdvertisements: Advertisement[] = [];
  public carouselAdvertisementsImages: IImage[] = [];
  public productsLoading: boolean = false;
  public total: number = 0;
  public showGoUpArrow: boolean = false;
  public searchTerm: string;
  public category: Category;
  public products: Product[] = [];
  private rootCategoryCode: string;
  private queryParamsSubscription: Subscription;
  private paramsSubscription: Subscription;
  private categories: Category[] = [];

  constructor(public router: Router,
              public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              private location: Location,
              private ngxLoaderService: NgxUiLoaderService,
              private productService: ProductService,
              private categoryService: CategoryService,
              private advertisementService: AdvertisementService,
              private dialog: MatDialog,
              private articleService: ArticleService) {
  }

  ngOnInit() {
    this.category = undefined;
    this.products = [];

    // Search term if passed in GET parameters
    this.searchTerm = this.route.snapshot.queryParamMap.get('term');
    // Subscribe to changes for term GET parameter
    this.queryParamsSubscription = this.route.queryParamMap.subscribe((params) => {
      if (params.keys.includes('term')) {
        this.searchTerm = params.get('term');
        this.loadProducts();
      }
    });

    // Subscribe to params changes
    const self = this;
    this.paramsSubscription = this.route.paramMap.subscribe((params) => {
      // Category code in params
      self.rootCategoryCode = params.has('categorycode') ? params.get('categorycode') : undefined;
      if (self.rootCategoryCode) {
        this.categoryService.getCategoryFromCode(self.rootCategoryCode).toPromise().then((category) => {
          this.category = category;

          if (!this.searchTerm) {
            this.products = [];
          }

          // Category has no children we can search for products
          if (!this.category.children) {
            this.loadProducts();
          }
        });
      } else if (!self.rootCategoryCode && !this.searchTerm) {
        this.router.navigate(['/categories']);
      }
    });

    // We look for home banner
    this.advertisementService.getAdvertisements('B2CWEB', 'PRODUCTS_BANNER').toPromise().then((advertisements) => {
      if (advertisements && advertisements.length > 0) {
        this.carouselAdvertisements = advertisements;
        this.carouselAdvertisements.forEach((ad) => {
          this.carouselAdvertisementsImages.push({
            href: ad.redirectionUrl,
            title: undefined,
            url: ad.mediaUrl})
        });
      }
    });
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
    this.paramsSubscription.unsubscribe();
  }

  /**
   * Initialize filters and load products.
   */
  private initProductsAndFilters() {
    this.products = [];

    // this.loadProducts();
  }

  /**
   * When product-item list is scrolled, load more products.
   */
  loadProducts() {
    this.products = [];

    // Fetch products
    this.ngxLoaderService.start('loadProducts');
    const self = this;

    this.productService.getProducts(this.category ? this.category.code : undefined,
      this.searchTerm).toPromise().then((products) => {
      self.products = products;
      self.ngxLoaderService.stop('loadProducts');
    }, err => {
      self.ngxLoaderService.stop('loadProducts');
    });
  }

  /**
   * Open modal to select a location for a product and add to cart
   * @param product
   */
  openSelectLocationModal(product: Product) {
    this.ngxLoaderService.start('getArticles');
    this.articleService.findAllArticles(product.id,
      (!product.properties['electronicTagTemplate'] || !(product.properties['electronicTagTemplate'].includes('3') || product.properties['electronicTagTemplate'].includes('31')))
                                       ).toPromise().then((articlesPage) => {
      const dialogRef = this.dialog.open(SelectLockerModalComponent, {
        data: {articles: articlesPage.content, product: product},
        panelClass: 'full-width-dialog'
      });

      this.ngxLoaderService.stop('getArticles');
    }, err => {
      this.ngxLoaderService.stop('getArticles');
    });
  }

  /**
   * Scroll event.
   * @param $event
   */
  scroll($event) {
    this.showGoUpArrow = window.scrollY > 0;
  }

  /**
   * Go to the top of the page.
   */
  goUp() {
    window.scroll(0, 0);
  }

  endSearch() {
    this.searchTerm = undefined;
    this.location.replaceState('/catalogue');
    this.loadProducts();
  }

  navigateTo(value?: string) {
    window.setTimeout(() => {
      if (value) {
        this.router.navigate(['/catalogue/', value]);
      } else {
        this.router.navigate(['/catalogue/']);
      }
    }, 300);
  }

  goBack() {
    this.location.back();
  }
}
