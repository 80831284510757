<div class="header full-width">
  <div class="row">
    <div class="col-sm-4 col-xs-2 left-side" [ngClass]="{ 'hidden-xs': !sharedVariablesService.organisation }">
      <span class="visible-xs" *ngIf="sharedVariablesService.organisation">
        <button class="menu blacklisted" mat-flat-button color="primary" (click)="toggleMenu()"><i></i></button>
      </span>
      <img class="logo link" [routerLink]="['/']" src="/assets/images/fraich-connection.svg"/>
      <div class="village" *ngIf="sharedVariablesService.organisation">
        FRAÎCH&rsquo; VILLAGE<br>
        {{ sharedVariablesService.organisation?.name }}
      </div>
    </div>
    <div class="visible-xs col-xs-3 text-center">
      <img class="logo link" [routerLink]="['/']" src="/assets/images/fraich-connection.svg"/>
    </div>
    <div class="col-sm-4 col-xs-7 col-sm-push-4 text-right" [ngClass]="{ 'col-xs-9': !sharedVariablesService.organisation }">
      <button mat-button class="accountButton blacklisted" *ngIf="!authenticationService.getCurrentUser()" (click)="toggleLogin()">
        <mat-icon class="blacklisted" svgIcon="account"></mat-icon>
        <br>Compte
      </button>
      <button mat-button class="accountButton blacklisted" *ngIf="authenticationService.getCurrentUser()" [routerLink]="['mon-compte']">
        <mat-icon class="blacklisted" svgIcon="account"></mat-icon>
        <br>Compte
      </button>
      <button mat-button class="cartButton blacklisted" [disabled]="!sharedVariablesService.transaction || (sharedVariablesService.transaction?.items.length === 0 && !showCartPanel)" (click)="toggleCart()" [ngClass]="{ animate: animateBadge }">
        <mat-icon class="material-icons blacklisted" [matBadgeHidden]="sharedVariablesService.transaction?.items.length === 0"
                  matBadge="{{ sharedVariablesService.transaction?.items?.length }}" matBadgeColor="accent" matBadgePosition="above after" [svgIcon]="sharedVariablesService.transaction?.items.length === 0 ? 'shopping-bag' : 'shopping-cart-full'"></mat-icon>
        <br><span class="blacklisted amount" *ngIf="sharedVariablesService.transaction?.items.length > 0">{{ sharedVariablesService.transaction?.totalAmount | price }}</span>
        <span class="blacklisted capitalize" *ngIf="!sharedVariablesService.transaction || sharedVariablesService.transaction?.items.length === 0">Panier</span>
      </button>
    </div>
    <div class="col-sm-4 col-sm-pull-4 col-xs-12" *ngIf="sharedVariablesService.transaction">
      <form class="search-form" [formGroup]="searchForm" (ngSubmit)="search()">
        <input formControlName="search" type="text" placeholder="Rechercher un produit">
        <button mat-stroked-button type="submit" color="primary"><mat-icon svgIcon="carotte"></mat-icon> OK</button>
      </form>
    </div>
  </div>
</div>
<app-menu [hidden]="!showMenu" (toggle)="toggleMenu()" (clickoutside)="closeMenu()" [enableBlackListing]="true"></app-menu>
<app-cart-panel [hidden]="!showCartPanel" (toggle)="toggleCart()" (clickoutside)="closeCartPanel()" [enableBlackListing]="true"></app-cart-panel>
<app-login-form [hidden]="!showLogin" [inMenu]="true" (toggle)="toggleLogin()" (customerAuthentified)="customerLoggedIn($event)" (clickoutside)="closeMenu()" [enableBlackListing]="true"></app-login-form>
