<div class="locker-content-component">
  <div class="locker-id">
    CASIER N° {{article.locationId | locationNumber}}
  </div>

  <div class="locker-informations flex mt10 mb10">
    <div class="locker-informations-left">
      <p class="product-name">{{article.product.name}}</p>
      <p class="price-per-weight" *ngIf="article.product.unitCapacityLabel && article.price.unitCapacityPrice">Prix/{{article.product.unitCapacityLabel}} : {{ article.price.unitCapacityPrice | price }}/{{article.product.unitCapacityLabel}}</p>
      <p *ngIf="article.product.packagingLabel">Conditionnement : {{ article.product.packagingLabel }}</p>
      <p class="expiry-date" *ngIf="!(article.product.properties['electronicTagTemplate']?.includes('3') || article.product.properties['electronicTagTemplate']?.includes('31'))">DLC : {{article.expiryDate | date: "shortDate"}}</p>

    </div>

    <div class="locker-informations-right text-right">
      <p class="price">{{article.totalAmount | currency: 'EUR'}}</p>
      <p class="origin" *ngIf="article && article.product && article.product.properties && article.product.properties['origin']">ORIGINE :
        <span>{{article.product.properties['origin']}}</span>
      </p>
      <p class="weight" *ngIf="article.quantityWithCoefficient && article.product.salesUnitLabel">{{article.quantityWithCoefficient}} {{article.product.salesUnitLabel}}</p>
    </div>
  </div>

  <div class="bottom-content flex">
    <div class="link primary mt10" (click)="openProductInformations(article)">
      + d'infos
    </div>

    <div>
      <button mat-flat-button color="primary" (click)="addToCart(article)">
        <mat-icon svgIcon="shopping-bag-black" class="mr5"></mat-icon>
        {{'Je réserve !' | uppercase}}</button>
    </div>
  </div>
</div>
